import * as React from "react";
import { Container } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import { styled } from "@mui/system";
import img from "../assets/images/AdmissionFee.jpg";
import Layout from "../layout/Layout";

const images = [
    { src: img, alt: "AdmissionFee.jpg" },
];

const ImageSlider = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
}));

const Slide = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.active': {
        display: 'flex',
    },
}));

const AdmissionFee = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    return (
        <Layout>
            <div className="flex flex-col justify-center bg-white">
                <Container>
                    <ImageSlider>
                        <ImageList
                            sx={{
                                width: '100%',
                                display: 'flex',
                                overflow: 'hidden',
                                justifyContent: 'center',
                            }}
                        >
                            {images.map((image, index) => (
                                <Slide key={index} className={index === currentIndex ? 'active' : ''}>
                                    <ImageListItem>
                                        <img src={image.src} alt={image.alt} style={{ width: '100%', height: 'auto' }} />
                                    </ImageListItem>
                                </Slide>
                            ))}
                        </ImageList>
                    </ImageSlider>
                </Container>
            </div>
        </Layout>
    );
}

export default AdmissionFee;
