import React, { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import {
  Container, Button, Typography, Box, Grid, TextField, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableRow, Paper, TableContainer
} from '@mui/material';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { createBooking } from '../../graphql/mutations';
import { listBookings, listCalendars, getCalendar } from '../../graphql/queries';
import Layout from '../../layout/Layout';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import OrganisationInfo from './OrganisationInfo';
import DatePickerComponent from './DatePickerComponent';
import PaxDetails from './PaxDetails';
import MilkPreBooking from './MilkPreBooking';
import TermsAndConditionsComponent from './TermsAndConditionsComponent';
import AWS from 'aws-sdk';
import WarningDialog from './warningdialog';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

const lambda = new AWS.Lambda();

const BookingComp = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, getValues, formState: { errors }, setError } = useForm();
  const [value, setValue] = useState(dayjs().add(2, 'day'));
  const [totalAmount, setTotalAmount] = useState(0);
  const [time, setTime] = useState('');
  const [message, setMessage] = useState("");
  const [isDatePickerEnabled, setIsDatePickerEnabled] = useState(true);
  const [enableAllDates, setEnableAllDates] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: "",
    email: "",
    firstName: "",
    lastName: ""
  });
  const [bookingList, setBookingList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState([]);
  const [paymentRequestId, setPaymentRequestId] = useState(null);
  const [timeSlotOptions, setTimeSlotOptions] = useState([]);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const watchedValues = watch(['tpAdult', 'tpChildBelowTwelve', 'tpChildBelowThree', 'tpSeniorCitizen', 'pbGoatMilkOrigin', 'pbGoatMilkChoco' , 'pbGoatMilkCaramel']);

  const defaultTimeSlots = [
    "09:00 - 10:30",
    "10:30 - 12:00"
  ];

  const handleClick = (me) => {
    setMessage(me);
    setOpen(true);
  };

  const handleEnableAllDatesClick = () => {
    setOpenDialog(true);
  };

  const closeWarningDialog = (continueAction) => {
    setOpenDialog(false);
    if (continueAction) {
      setEnableAllDates(true);
      setTimeSlotOptions(defaultTimeSlots); // Set default time slots
    }
  };

  const closeEventDialog = () => {
    setDialogOpen(false);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((r) => {
        setUserInfo({
          id: r.attributes.sub,
          email: r.attributes.email,
          firstName: r.attributes.given_name,
          lastName: r.attributes.family_name
        });
      })
      .catch(() => navigate("/"));

    fetchBookingsAndCalendars();
  }, [navigate]);

  useEffect(() => {
    const total = (parseInt(getValues('tpAdult') || 0) * 10) +
        (parseInt(getValues('tpChildBelowTwelve') || 0) * 7) +
        (parseInt(getValues('tpSeniorCitizen') || 0) * 7) +
        (parseInt(getValues('pbGoatMilkOrigin') || 0) * 12)+
        (parseInt(getValues('pbGoatMilkChoco') || 0) * 13)+
        (parseInt(getValues('pbGoatMilkCaramel') || 0) * 13);
    setTotalAmount(total);
  }, [watchedValues, getValues]);

  const fetchBookingsAndCalendars = async () => {
    try {
      const bookingData = await API.graphql({ query: listBookings });
      const bookings = bookingData.data.listBookings.items;
      setBookingList(bookings);

      const calendarData = await API.graphql({ query: listCalendars });
      const calendars = calendarData.data.listCalendars.items;
      setCalendarList(calendars);
    } catch (error) {
      console.error('Error fetching bookings and calendars', error);
    }
  };

  const handleDayClick = (date) => {
    setSelectedDate(date);
    const dayEvents = calendarList.filter(event => event.date === dayjs(date).format('YYYY-MM-DD'));
    setDialogContent(dayEvents);
    setDialogOpen(true);
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      return !calendarList.some(event => event.date === formattedDate);
    }
    return false;
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript('https://hit-pay.com/hitpay.js')
        .then(() => {
            if (window.HitPay) {
                window.HitPay.init('http://securecheckout.hit-pay.com/payment-request', {
                    domain: 'hit-pay.com',
                    apiDomain: 'hit-pay.com'
                });
            }
        })
        .catch((error) => {
            console.error('Error loading HitPay script:', error);
        });

    window.addEventListener('message', handlePaymentMessage);

    return () => {
        window.removeEventListener('message', handlePaymentMessage);
    };
  }, []);

  const handlePaymentMessage = (event) => {
    if (event.origin !== 'https://hit-pay.com') {
        return;
    }

    const { type, props } = event.data;

    console.log('Received payment message:', event.data);

    if (type === 'success') {
        handleClick("Payment successful");
        if (window.bookingData) {
            saveBookingData(window.bookingData);
        } else {
            console.error("Booking data is missing in the payment response");
        }
    } else if (type === 'error') {
        handleClick(`Payment failed: ${props.error.message}`);
        console.error("Payment error:", props.error);
    } else {
        console.log('Unknown message type:', type);
    }
  };

  const saveBookingData = async (bookingData) => {
    const maxRetries = 3; // 最大重试次数
    let attempts = 0;
  
    while (attempts < maxRetries) {
      try {
        const result = await API.graphql({
          query: createBooking,
          variables: { 
            input: {
              tpAdult: bookingData.tpAdult,
              phoneNumber: bookingData.phoneNumber,
              organisationName: bookingData.organisationName,
              tpChildBelowThree: bookingData.tpChildBelowThree,
              tpChildBelowTwelve: bookingData.tpChildBelowTwelve,
              tpSeniorCitizen: bookingData.tpSeniorCitizen,
              pbGoatMilkOrigin: bookingData.pbGoatMilkOrigin,
              pbGoatMilkChoco: bookingData.pbGoatMilkChoco,
              pbGoatMilkCaramel: bookingData.pbGoatMilkCaramel,
              total: bookingData.total,
              userID: bookingData.userID,
              date: bookingData.date,
              time: bookingData.time
            }
          }
        });
        console.log('Booking save result:', result);
        handleClick("Booking saved successfully");
        navigate('/BookingDetails');
        fetchBookingsAndCalendars();
        return;
      } catch (error) {
        attempts += 1;
        console.error(`Error saving booking data, attempt ${attempts}:`, error);
        
        if (error.errors) {
          error.errors.forEach(err => console.error(err.message));
        }
  
        if (attempts === maxRetries) {
          handleClick(`Error saving booking data after ${attempts} attempts: ${error.message || "Unknown error"}`);
          console.log('Unsuccessful booking data:', bookingData);
        } else {
          handleClick(`Retrying to save booking data (${attempts}/${maxRetries})...`);
        }
      }
    }
  };  

  const onSubmit = async (data) => {
    if (!termsAccepted) {
      handleClick("You must accept the Terms & Conditions to proceed.");
      return;
    }
  
    if (totalAmount === 0) {
      handleClick("Total amount must be greater than 0 to proceed.");
      return;
    }
  
    if (!time) {
      handleClick("Please select a time slot.");
      return;
    }
  
    const totalPeopleBooking = parseInt(data.tpAdult) + parseInt(data.tpChildBelowThree) + parseInt(data.tpChildBelowTwelve) + parseInt(data.tpSeniorCitizen);
    const selectedBookingDate = dayjs(value).format('YYYY-MM-DD');
    const selectedTimeSlot = time;
  
    const totalBookedInTimeSlot = bookingList
      .filter(booking => booking.date === selectedBookingDate && booking.time === selectedTimeSlot)
      .reduce((acc, booking) => acc + booking.tpAdult + booking.tpChildBelowThree + booking.tpChildBelowTwelve + booking.tpSeniorCitizen, 0);
  
    if (totalBookedInTimeSlot + totalPeopleBooking > 70) {
      handleClick("Booking exceeds the maximum capacity for the selected time slot.");
      return;
    }

    const totalGoatMilkBooking = parseInt(data.pbGoatMilkOrigin || 0) + parseInt(data.pbGoatMilkChoco || 0) + parseInt(data.pbGoatMilkCaramel || 0);
    const totalBookedGoatMilkInTimeSlot = bookingList
      .filter(booking => booking.date === selectedBookingDate && booking.time === selectedTimeSlot)
      .reduce((acc, booking) => acc + booking.pbGoatMilkOrigin + booking.pbGoatMilkChoco + booking.pbGoatMilkCaramel, 0);
    
    if (totalBookedGoatMilkInTimeSlot + totalGoatMilkBooking > 25) {
      handleClick("Goat Milk Booking limit over 25 for the selected time slot.");
      return;
    }

    // Check if the selected date is in the calendar
    const isDateInCalendar = calendarList.some(event => event.date === selectedBookingDate);
  
    if (!isDateInCalendar && totalPeopleBooking <= 24) {
      handleClick("Booking must be for more than 25 people if the selected date is not in the event time.");
      return;
    }

    const parseIntOrZero = (value) => {
      return value === '' || value === null || value === undefined ? 0 : parseInt(value, 10);
    };
    
    const newBookingData = {
      tpAdult: parseIntOrZero(data.tpAdult),
      phoneNumber: data.phoneNumber,
      organisationName: data.organisationName,
      tpChildBelowThree: parseIntOrZero(data.tpChildBelowThree),
      tpChildBelowTwelve: parseIntOrZero(data.tpChildBelowTwelve),
      tpSeniorCitizen: parseIntOrZero(data.tpSeniorCitizen),
      pbGoatMilkOrigin: parseIntOrZero(data.pbGoatMilkOrigin),
      pbGoatMilkChoco: parseIntOrZero(data.pbGoatMilkChoco),
      pbGoatMilkCaramel: parseIntOrZero(data.pbGoatMilkCaramel),
      total: totalAmount,
      userID: userInfo.id,
      date: new Date(value).toISOString().split("T")[0],
      time: time,
      fullName: `${userInfo.firstName} ${userInfo.lastName}`
    };
  
    setBookingData(newBookingData);
    setConfirmDialogOpen(true);
    setWarningDialogOpen(true);
  };
  
  const confirmBooking = () => {
    setConfirmDialogOpen(false);
    
    const isValidBookingDate = validDates.includes(bookingData.date);
    const selectedTimeSlot = time.split(" - ");
    const isValidBookingTime = calendarList.some(event => {
      const eventStartTime = dayjs(event.time, 'HH:mm').format('HH:mm');
      return event.date === bookingData.date && eventStartTime === selectedTimeSlot[0];
    });
    
    if (!isValidBookingDate || !isValidBookingTime) {
      saveBookingData(bookingData);
      return;
    }
    
    window.bookingData = bookingData;
    
    try {
      const params = {
        FunctionName: 'CreatePaymentRequest-dev',
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          arguments: {
            input: {
              email: userInfo.email,
              amount: bookingData.total,
              currency: 'MYR',
              redirect_url: window.location.href,
              webhook: 'http://your-webhook-url.com',
              fullName: `${userInfo.firstName} ${userInfo.lastName}`
            }
          }
        })
      };
    
      lambda.invoke(params, (err, data) => {
        if (err) {
          console.error("Error creating payment request:", err);
          handleClick(`Error creating payment request: ${err.message || "Unknown error"}`);
        } else {
          const paymentData = JSON.parse(data.Payload);
    
          if (paymentData && paymentData.paymentUrl) {
            setPaymentRequestId(paymentData.id);
            handleClick("Payment request created, processing payment...");
    
            if (window.HitPay && window.HitPay.toggle) {
              window.HitPay.toggle({
                paymentRequest: paymentData.id,
                amount: bookingData.total,
                currency: 'MYR',
                email: userInfo.email,
                containerId: 'payment-form'
              });
            } else {
              throw new Error('HitPay Drop-in is not initialized correctly.');
            }
          } else {
            throw new Error('Failed to get payment URL');
          }
        }
      });
    } catch (error) {
      console.error("Error creating payment request:", error);
      handleClick(`Error creating payment request: ${error.message || "Unknown error"}`);
    }
  };
  
  const validDates = calendarList.map(event => dayjs(event.date).format('YYYY-MM-DD'));
  
  const isValidDate = (date) => {
    return validDates.includes(dayjs(date).format('YYYY-MM-DD'));
  };
  
  const shouldDisableDate = (date) => {
    return !enableAllDates && isDatePickerEnabled && !isValidDate(date);
  };  

  return (
    <Layout>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
      <Container maxWidth="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" mt={4} mb={2}>
            Book Your Visit
          </Typography>
          <Grid container spacing={4}>
            <UserInfo userInfo={userInfo} />
            <OrganisationInfo register={register} errors={errors} />
            <DatePickerComponent
              value={value}
              setValue={setValue}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleDayClick={handleDayClick}
              tileDisabled={tileDisabled}
              handleEnableAllDatesClick={handleEnableAllDatesClick}
              shouldDisableDate={shouldDisableDate}
              dialogOpen={dialogOpen}
              closeEventDialog={closeEventDialog}
              openDialog={openDialog}
              closeWarningDialog={closeWarningDialog}
              dialogContent={dialogContent}
              setDialogContent={setDialogContent}
              setDialogOpen={setDialogOpen}
              bookingList={bookingList}
              calendarList={calendarList}
              time={time}
              setTime={setTime}
              timeSlotOptions={timeSlotOptions}
              setTimeSlotOptions={setTimeSlotOptions}
              defaultTimeSlots={defaultTimeSlots}
            />
            <Grid item xs={12}>
              <Typography variant="h6">Phone Number</Typography>
              <TextField
                label="+60"
                fullWidth
                variant="outlined"
                {...register("phoneNumber", { 
                  required: "Phone number is required", 
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Phone number must contain only numbers"
                  }
                })}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
            <PaxDetails register={register} errors={errors} />
            <MilkPreBooking register={register} errors={errors} />
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>Total Price (RM):</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={totalAmount}
                  disabled
                  {...register("total")}
                  error={Boolean(errors.total)}
                  helperText={errors.total?.message}
                />
              </Box>
            </Grid>
            <TermsAndConditionsComponent termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} />
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  border: '1px solid black',
                  '&:hover': {
                    bgcolor: 'black',
                    color: 'white',
                  }
                }}
              >
                Book Now
              </Button>
            </Grid>
          </Grid>
        </form>
        <div id="payment-form"></div>
      </Container>
      <Dialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
      >
        <DialogTitle>Confirm Your Booking</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><strong>Full Name:</strong></TableCell>
                  <TableCell>{bookingData?.fullName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Organisation Name:</strong></TableCell>
                  <TableCell>{bookingData?.organisationName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Date:</strong></TableCell>
                  <TableCell>{bookingData?.date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Time:</strong></TableCell>
                  <TableCell>{bookingData?.time}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Adults:</strong></TableCell>
                  <TableCell>{bookingData?.tpAdult}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Children Below 3:</strong></TableCell>
                  <TableCell>{bookingData?.tpChildBelowThree}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Children Below 12:</strong></TableCell>
                  <TableCell>{bookingData?.tpChildBelowTwelve}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Senior Citizen:</strong></TableCell>
                  <TableCell>{bookingData?.tpSeniorCitizen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Pre-order Goat Milk Origin Flavor:</strong></TableCell>
                  <TableCell>{isNaN(bookingData?.pbGoatMilkOrigin) ? 0 : bookingData?.pbGoatMilkOrigin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Pre-order Goat Milk Chocolate Flavor:</strong></TableCell>
                  <TableCell>{isNaN(bookingData?.pbGoatMilkChoco) ? 0 : bookingData?.pbGoatMilkChoco}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Pre-order Goat Milk Caramel Flavor:</strong></TableCell>
                  <TableCell>{isNaN(bookingData?.pbGoatMilkCaramel) ? 0 : bookingData?.pbGoatMilkCaramel}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Total Price:</strong></TableCell>
                  <TableCell><strong>RM {bookingData?.total}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">Cancel</Button>
          <Button onClick={confirmBooking} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        message={message}
      />
    </Layout>
  );
};

export default BookingComp;
