import React from 'react';
import { Alert, Grid } from '@mui/material';
import TermsAndConditions from "../TermsAndConditions";

const TermsAndConditionsComponent = ({ termsAccepted, setTermsAccepted }) => (
  <Grid item xs={12}>
    <Alert severity="" sx={{ bgcolor: 'pink', border: '1px solid black' }}>
      <TermsAndConditions
        checked={termsAccepted}
        onChange={(e) => setTermsAccepted(e.target.checked)}
      />
    </Alert>
  </Grid>
);

export default TermsAndConditionsComponent;
