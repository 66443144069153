import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';

const PaxDetails = ({ register, errors }) => (
  <Grid item xs={12}>
    <Typography variant="h6">Total Pax</Typography>
    <Typography>Adult: MYR 10.00</Typography>
    <Typography>Children (3-12 YO): MYR 7.00</Typography>
    <Typography>Children (Below 3 YO): FOC</Typography>
    <Typography>Senior Citizen (Above 65 YO): MYR 7.00</Typography>
    <Grid container spacing={2} mt={2}>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          label="Adult"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("tpAdult", { min: 0 })}
          error={Boolean(errors.tpAdult)}
          helperText={errors.tpAdult?.message}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          label="Children (3-12 yo)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("tpChildBelowTwelve", { min: 0 })}
          error={Boolean(errors.tpChildBelowTwelve)}
          helperText={errors.tpChildBelowTwelve?.message}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          label="Children (below 3)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("tpChildBelowThree", { min: 0 })}
          error={Boolean(errors.tpChildBelowThree)}
          helperText={errors.tpChildBelowThree?.message}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          label="Senior Citizen (above 65)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("tpSeniorCitizen", { min: 0 })}
          error={Boolean(errors.tpSeniorCitizen)}
          helperText={errors.tpSeniorCitizen?.message}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default PaxDetails;
