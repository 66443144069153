import React, { useEffect, useState, useRef } from 'react';
import { API, Auth } from 'aws-amplify';
import { listBookings, listCalendars } from '../graphql/queries';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Divider, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import Layout from '../layout/Layout';
import dayjs from 'dayjs';

const BookingDetails = () => {
  const [bookings, setBookings] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const printRef = useRef();

  useEffect(() => {
    const fetchBookingsAndAppointments = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserInfo({
          id: user.attributes.sub,
          email: user.attributes.email,
          firstName: user.attributes.given_name,
          lastName: user.attributes.family_name,
        });

        const bookingData = await API.graphql({
          query: listBookings,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        const calendarData = await API.graphql({
          query: listCalendars,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        const userBookings = bookingData.data.listBookings.items.filter(
          (booking) => booking.userID === user.attributes.sub
        );

        const calendars = calendarData.data.listCalendars.items;

        const calendarEvents = new Map();
        calendars.forEach(calendar => {
          const date = calendar.date;
          const startTime = dayjs(calendar.time, 'HH:mm');
          const endTime = startTime.add(1.5, 'hour');
          const timeRange = `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
          if (!calendarEvents.has(date)) {
            calendarEvents.set(date, new Set());
          }
          calendarEvents.get(date).add(timeRange);
        });

        const appointmentsList = userBookings.filter(booking => {
          const date = booking.date;
          const timeRange = booking.time;
          return calendarEvents.has(date) && calendarEvents.get(date).has(timeRange);
        });

        const bookingsList = userBookings.filter(booking => !appointmentsList.includes(booking));

        setAppointments(appointmentsList);
        setBookings(bookingsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bookings and appointments', error);
        setLoading(false);
      }
    };

    fetchBookingsAndAppointments();
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const filteredAppointments = appointments.filter(appointment => {
    const date = dayjs(appointment.date);
    return (!selectedYear || date.year() === parseInt(selectedYear)) &&
           (!selectedMonth || date.month() === parseInt(selectedMonth) - 1) &&
           (!selectedDay || date.date() === parseInt(selectedDay));
  });

  const filteredBookings = bookings.filter(booking => {
    const date = dayjs(booking.date);
    return (!selectedYear || date.year() === parseInt(selectedYear)) &&
           (!selectedMonth || date.month() === parseInt(selectedMonth) - 1) &&
           (!selectedDay || date.date() === parseInt(selectedDay));
  });

  const uniqueYears = [...new Set([...appointments, ...bookings].map(item => dayjs(item.date).year()))];
  const uniqueMonths = [...Array(12).keys()].map(i => i + 1);
  const uniqueDays = [...Array(31).keys()].map(i => i + 1);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;

  const renderTable = (data, isAppointment) => (
    <TableContainer component={Paper} sx={{ marginBottom: 2, maxWidth: 600 }}>
      <Table size="small">
        <TableBody>
          {data.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }}><strong>{isAppointment ? 'Booking ID' : 'Appointment ID'}:</strong></TableCell>
                <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }}><strong>Full Name:</strong></TableCell>
                <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{fullName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }}><strong>Organisation Name:</strong></TableCell>
                <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.organisationName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }}><strong>Date:</strong></TableCell>
                <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.date}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }}><strong>Time:</strong></TableCell>
                <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.time}</TableCell>
              </TableRow>
              {isAppointment ? (
                <>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Adults:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.tpAdult} pax</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Children Below 3:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.tpChildBelowThree} pax</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Children Below 12:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.tpChildBelowTwelve} pax</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Senior Citizen:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.tpSeniorCitizen} pax</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Pre-order Goat Milk Origin Flavor:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.pbGoatMilkOrigin} pcs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Pre-order Goat Milk Chocolate Flavor:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.pbGoatMilkChoco} pcs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Pre-order Goat Milk Caramel Flavor:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}>{item.pbGoatMilkCaramel} pcs</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '4px 8px' }}><strong>Total Price:</strong></TableCell>
                    <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}><strong>RM {item.total}</strong></TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell sx={{ padding: '4px 8px' }}><strong>Status:</strong></TableCell>
                  <TableCell sx={{ padding: '4px 8px', borderLeft: '1px solid #ccc' }}><strong>{item.status || 'PENDING'}</strong></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2}><Divider /></TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload page to restore original contents
  };

  return (
    <Layout>
      <Container>
        <Typography variant="h4" mt={4} mb={2}>Filter Bookings</Typography>
        <Box display="flex" mb={2} gap={2}>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Year</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange} label="Year">
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {uniqueYears.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Month</InputLabel>
            <Select value={selectedMonth} onChange={handleMonthChange} label="Month">
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {uniqueMonths.map(month => (
                <MenuItem key={month} value={month}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Day</InputLabel>
            <Select value={selectedDay} onChange={handleDayChange} label="Day">
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {uniqueDays.map(day => (
                <MenuItem key={day} value={day}>{day}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <div ref={printRef}>
          <Typography variant="h4" mt={4} mb={2}>Payment Success Detail</Typography>
          {filteredAppointments.length === 0 ? (
            <Typography>No booking found. </Typography>
          ) : (
            renderTable(filteredAppointments, true)
          )}
        </div>
        <Button variant="contained" onClick={handlePrint} sx={{ mt: 2 }}>Print Payment Success Detail</Button>
        <Typography variant="h4" mt={4} mb={2}>Appointment Details</Typography>
        {filteredBookings.length === 0 ? (
          <Typography>No appointments found.</Typography>
        ) : (
          renderTable(filteredBookings, false)
        )}
      </Container>
    </Layout>
  );
};

export default BookingDetails;
