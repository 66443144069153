import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../theme";
import { API, graphqlOperation } from 'aws-amplify';
import { listBookings } from '../../graphql/queries';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../components/Header";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";
import ProgressCircle from "../components/ProgressCircle";
import Layout from '../pages/global/Layout';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [timePeriod] = useState('yearly');
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await API.graphql(graphqlOperation(listBookings));
        const bookings = result.data.listBookings.items;
        const filteredBookings = bookings.filter(booking => booking.status !== 'REJECTED');
        
        const revenueByMonth = Array(12).fill(0);
        filteredBookings.forEach(booking => {
          const month = new Date(booking.date).getMonth();
          revenueByMonth[month] += booking.total;
        });

        setMonthlyRevenue(revenueByMonth);
      } catch (error) {
        console.error('Error fetching bookings', error);
      }
    };

    fetchData();
  }, []);

  // Transform monthlyRevenue to the format required by LineChart
  const lineChartData = [
    {
      id: 'Total Bookings',
      data: monthlyRevenue.map((revenue, index) => ({
        x: new Date(0, index).toLocaleString('default', { month: 'long' }),
        y: revenue
      }))
    }
  ];

  return (
    <Layout>
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Welcome to Admin Dashboard" />
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="minmax(100px, auto)"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 8"
            backgroundColor={colors.primary[400]}
            p="20px"
            borderRadius="8px"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Annual Revenue Generated
                </Typography>
                <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                  RM {monthlyRevenue.reduce((a, b) => a + b, 0).toFixed(2)}
                </Typography>
              </Box>
              {/* <IconButton>
                <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.greenAccent[500] }} />
              </IconButton> */}
            </Box>
            {/* <Box height="250px" mt="20px">
              <LineChart data={lineChartData} isDashboard={true} />
            </Box> */}
          </Box>
          
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            borderRadius="8px"
            p="20px"
          >
            {/* <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom={`4px solid ${colors.primary[500]}`} p="15px">
              <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                Recent Transactions
              </Typography>
            </Box> */}
            {/* Display the fetched bookings */}
            {/* Map over recent transactions here */}
          </Box>

          {/* ROW 2 */}
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="20px"
            borderRadius="8px"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Campaign
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <InputLabel id="month-select-label" sx={{ color: colors.grey[100] }}>Month</InputLabel>
              <Select
                labelId="month-select-label"
                id="month-select"
                value={month}
                label="Month"
                onChange={(e) => setMonth(e.target.value)}
                sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i} value={i + 1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
              <ProgressCircle size="125" />
              <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mt: "15px" }}>
                RM {monthlyRevenue[month - 1]?.toFixed(2)} revenue generated
              </Typography>
              <Typography color={colors.grey[100]}>
                Includes extra misc expenditures and costs
              </Typography>
            </Box>
          </Box>
          
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="20px"
            borderRadius="8px"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Sales Quantity
            </Typography>
            <Box height="250px" mt="20px">
              <BarChart data={monthlyRevenue} isDashboard={true} />
            </Box>
          </Box>

          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            p="20px"
            borderRadius="8px"
          >
            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
              Booking Distribution
            </Typography>
            <Box height="250px" mt="20px">
              <PieChart data={monthlyRevenue} isDashboard={true} timePeriod={timePeriod} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Dashboard;
