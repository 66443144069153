// src/pages/global/Layout.jsx
import React from 'react';
import Sidebar from './Sidebar';
import { CssBaseline } from '@mui/material';

const layoutStyle = {
  display: 'flex',
  height: '100vh',
  backgroundColor: '#ffffff', // Set the background color directly
};

const sidebarStyle = {
  height: 'auto',
  width: '250px',
  flexShrink: 0,
};

const mainContentStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden', // Ensure the content area does not overflow the container
  backgroundColor: '#ffffff', // Set the background color directly
};

const contentStyle = {
  flex: 1,
  padding: '20px',
  overflowY: 'auto', // Ensure the content area is scrollable
};

const Layout = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <div style={layoutStyle}>
        <div style={sidebarStyle}>
          <Sidebar />
        </div>
        <div style={mainContentStyle}>
          <div style={contentStyle}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
