import React, { useEffect, useState } from 'react';
import { API, Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports'; // Adjust the path as needed
import { listBookings } from '../../graphql/queries'; // Ensure the path is correct
import { ResponsiveBar } from '@nivo/bar';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

Amplify.configure(awsconfig);

const calculateDailyTotals = (bookings) => {
  const totals = {};

  bookings.forEach((booking) => {
    const date = dayjs(booking.date).format('YYYY-MM-DD'); // Format the booking date
    if (!totals[date]) {
      totals[date] = {
        date,
        "GoatMilk Origin": 0,
        "GoatMilk Choco": 0,
        "GoatMilk Caramel": 0,
      };
    }
    totals[date]["GoatMilk Origin"] += booking.pbGoatMilkOrigin || 0;
    totals[date]["GoatMilk Choco"] += booking.pbGoatMilkChoco || 0;
    totals[date]["GoatMilk Caramel"] += booking.pbGoatMilkCaramel || 0;
  });

  return Object.values(totals);
};

const getFirstDayOfMonth = (date) => {
  return dayjs(date).startOf('month').toDate();
};

const getLastDayOfMonth = (date) => {
  return dayjs(date).endOf('month').toDate();
};

const BarChart = ({ isDashboard = false }) => {
  const [GoatMilkData, setGoatMilkData] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth(new Date()));
  const [endDate, setEndDate] = useState(getLastDayOfMonth(new Date()));

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingData = await API.graphql({ query: listBookings });

        if (bookingData.data && bookingData.data.listBookings && bookingData.data.listBookings.items) {
          // Filter bookings within the selected date range
          const bookings = bookingData.data.listBookings.items.filter(booking => {
            const bookingDate = dayjs(booking.date).toDate(); // Convert to native Date object for comparison
            return bookingDate >= startDate && bookingDate <= endDate;
          });

          const dailyTotals = calculateDailyTotals(bookings);
          setGoatMilkData(dailyTotals);
        } else {
          console.error('No bookings found.');
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, [startDate, endDate]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', height: isDashboard ? '100%' : 'auto' }}>
      {!isDashboard && (
        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dayjs(startDate)}
              onChange={(newDate) => setStartDate(newDate ? newDate.toDate() : getFirstDayOfMonth(new Date()))}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={dayjs(endDate)}
              onChange={(newDate) => setEndDate(newDate ? newDate.toDate() : getLastDayOfMonth(new Date()))}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      )}
      <Box style={{ height: isDashboard ? '250px' : '400px', width: isDashboard ? '100%' : '80%', maxWidth: '800px' }}>
        <ResponsiveBar
          data={GoatMilkData}
          keys={["GoatMilk Origin", "GoatMilk Choco", "GoatMilk Caramel"]}
          indexBy="date"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Date",
            legendPosition: "middle",
            legendOffset: 32,
            tickTextColor: "#fff", // Change tick text color
            legendTextColor: "#fff", // Change legend text color
            fontSize: 12, // Change font size
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Goat Milk Sales",
            legendPosition: "middle",
            legendOffset: -40,
            tickTextColor: "#fff", // Change tick text color
            legendTextColor: "#fff", // Change legend text color
            fontSize: 12, // Change font size
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              text: {
                fontSize: 12, // Change font size
              },
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          barAriaLabel={function (e) {
            return e.id + ": " + e.formattedValue + " on date: " + e.indexValue;
          }}
        />
      </Box>
    </div>
  );
};

export default BarChart;
