import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import SignIn from "./Login";
import SignUp from "./SignUp";
import Otp from "./Otp";
import img from "../../assets/images/logo.png";
import TutorialDialog from "../TutorialDialog"; // 引入教程对话框组件

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Header = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalNo, setModalNo] = useState(1);
    const [cred, setCred] = useState({ email: "", pass: "" });
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [tutorialOpen, setTutorialOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleTutorialOpen = () => setTutorialOpen(true);
    const handleTutorialClose = () => setTutorialOpen(false);

    const changeModalThree = () => setModalNo(3);
    const getCred = (email, pass) => setCred({ email, pass });

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUserInfo({
                    id: user.attributes.sub,
                    email: user.attributes.email,
                    firstName: user.attributes.given_name,
                    lastName: user.attributes.family_name
                });
            })
            .catch(() => {
                setUserInfo(null);
            });
    }, []);

    const bookingCheck = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            handleTutorialOpen();
        } catch {
            handleClickOpen();
        }
    };

    const handleTutorialComplete = () => {
        handleTutorialClose();
        navigate("/booking");
    };

    const changeModal = (m) => {
        setModalNo(m === 1 ? 2 : 1);
    };

    const logout = async () => {
        try {
            await Auth.signOut();
            setUserInfo(null);
            navigate("/");
            window.location.reload();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <div
                className="flex flex-col md:flex-row justify-between items-center px-5 py-2 md:px-10 md:py-3 lg:px-20 lg:py-4 bg-white border-b border-black">
                <div className="w-full md:w-1/4 flex justify-start md:justify-center items-center">
                    <img
                        src={img}
                        className="h-16 md:h-28 w-auto cursor-pointer"
                        alt="Logo"
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="flex-grow flex justify-center items-center">
                    <div className="hidden md:flex space-x-5 items-center">
                        <button className="hover:text-gray-700" onClick={() => navigate("/")}>Home</button>
                        <button className="hover:text-gray-700" onClick={() => navigate("/location")}>Location</button>
                        <button className="hover:text-gray-700" onClick={() => navigate("/event")}>Events</button>
                        <button className="hover:text-gray-700"
                                onClick={() => navigate("/transportation")}>Transportation
                        </button>
                        <button className="hover:text-gray-700"
                                onClick={() => navigate("/BookingDetails")}>View Booking Details
                        </button>
                        <button className="hover:text-gray-700"
                                onClick={() => navigate("/AdmissionFee")}>Admission Fees
                        </button>
                        <button
                            className="px-4 py-2 bg-white rounded-lg shadow-sm border border-black hover:bg-gray-100"
                            onClick={bookingCheck} // 确保只有点击这个按钮时才调用 bookingCheck
                        >
                            Book Now!
                        </button>
                        {userInfo && (
                            <button className="hover:text-gray-700" onClick={logout}>Log out</button>
                        )}
                    </div>
                </div>
                <div className="md:hidden">
                    <button className="hover:text-gray-700" onClick={toggleMobileMenu}>Menu</button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden bg-white border-b border-black">
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/")}>Home</button>
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/location")}>Location</button>
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/transportation")}>Transportation</button>
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/event")}>Events</button>
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/BookingDetails")}>BookingDetails</button>
                    <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={() => navigate("/AdmissionFee")}>Admission Fees</button>
                    <button
                        className="block py-2 px-4 w-full text-left bg-white rounded-lg shadow-sm border border-black hover:bg-gray-100"
                        onClick={bookingCheck} // 确保只有点击这个按钮时才调用 bookingCheck
                    >
                        Book Now!
                    </button>
                    {userInfo && (
                        <button className="block py-2 px-4 w-full text-left hover:bg-gray-100" onClick={logout}>Log out</button>
                    )}
                </div>
            )}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {modalNo === 1 ? (
                    <SignIn handleClose={handleClose} />
                ) : modalNo === 2 ? (
                    <SignUp changeModalThree={changeModalThree} getCred={getCred} />
                ) : (
                    <Otp email={cred.email} pass={cred.pass} handleClose={handleClose} />
                )}
                <DialogActions>
                    <Button onClick={() => changeModal(modalNo)}>Sign up!</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Tutorial Dialog */}
            <TutorialDialog
                open={tutorialOpen}
                handleClose={handleTutorialClose}
                handleComplete={handleTutorialComplete}
            />
        </>
    );
}

export default Header;
