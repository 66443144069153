import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useForm} from "react-hook-form";
import {useRef, useState} from "react";
import {Auth} from "aws-amplify";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                The Shepherd Farm
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp({changeModalThree, getCred}) {

    const [showPassword, setShowPassword] = React.useState(false);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setError
    } = useForm()

    const password = useRef({});
    password.current = watch("password", "");



    const onSubmit = async (data) => {
        getCred(data.email, data.password)
        await Auth.signUp({
            username: data.email,
            password: data.password,
            attributes: {
                given_name: data?.firstName,
                family_name: data?.lastName,
            },
        }).then((r) => {
            setLoading(false)
            changeModalThree()
        })
            .catch((error) => {
                setLoading(false)
                console.error("sign in :", error.code);
                console.error("sign in :", error.message);
                if (error.code === "UsernameExistsException") {
                    setError(
                        "email",
                        {
                            type: "custom",
                            message: "This email is already registered",
                        },
                        { shouldFocus: true }
                    );
                }
                if (error.code === "InvalidPasswordException") {
                    setError(
                        "password",
                        {
                            type: "custom",
                            message:
                                "Password has missing pattern. Use at least eight characters, one letter and one number",
                        },
                        { shouldFocus: true }
                    );
                }
                if (error.code === "UserNotConfirmedException") {
                }
            });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                    {/*    <LockOutlinedIcon />*/}
                    {/*</Avatar>*/}
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Box  sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="First Name"
                                name="firstName"
                                autoComplete="firstName"
                                autoFocus
                                {...register("firstName", {
                                    required: { value: true, message: "FirstName is required" },
                                })}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName?.message}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lastName"
                                autoFocus
                                {...register("lastName", {
                                    required: { value: true, message: "LastName is required" },
                                })}
                                error={Boolean(errors.lastName)}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                {...register("email", {
                                    required: { value: true, message: "Email is required" },
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Email address is not valid",
                                    },
                                })}

                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                                type={showPassword ? "text" : "password"}
                                {...register("password", {
                                    required: { value: true, message: "Password is required" },
                                    pattern: {
                                        value:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+/\.])[A-Za-z\d!@#$%^&*()_+/\.]{8,}$/,
                                        message:
                                            "Please enter at least one small letter, one capital letter, one number and a special character. Minimum length is 8 characters.",
                                    },
                                })}
                                autoComplete="current-password"
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Re-Type Password"
                                type={showRePassword ? "text" : "password"}
                                {...register("rePassword", {
                                    required: {
                                        value: true,
                                        message: "Re Enter Password is required",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "Minimum length of password is 8",
                                    },
                                    validate: (value) =>
                                        value === password.current || "The passwords do not match",
                                })}
                                id="password"
                                autoComplete="current-password"

                                error={Boolean(errors.rePassword)}
                                helperText={errors.rePassword?.message}
                            />
                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                            {/*    label="Remember me"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </form>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}