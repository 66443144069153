import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';

const UserInfo = ({ userInfo }) => (
  <Grid item xs={12}>
    <Typography variant="h6">User's name:</Typography>
    <TextField
      fullWidth
      variant="outlined"
      value={`${userInfo.firstName} ${userInfo.lastName}`}
      disabled
    />
  </Grid>
);

export default UserInfo;
