import * as React from "react";
import Layout from "../../layout/Layout";
import event2Image from "../../assets/images/event2.jpg";

const Event2 = () => {
    return (
        <Layout>
            <div className="flex flex-col bg-white">
                <img
                    loading="lazy"
                    src={event2Image}
                    className="self-center mt-20 w-full shadow-lg aspect-[1.49] max-w-[1200px] max-md:mt-10 max-md:max-w-full"
                />
                <div className="flex gap-5 justify-between self-center max-w-full w-[1056px] max-md:flex-wrap">
                    <div className="flex flex-col px-5 pt-20 pb-4 text-black max-md:max-w-full">
                    <div className="mt-6 text-6xl font-bold tracking-tighter max-md:max-w-full max-md:text-4xl">
                            Experience the Green Serenity and Delightful Weather{" "}
                        </div>
                        <div className="mt-6 text-2xl leading-9 text-zinc-500 max-md:max-w-full">
                            Escape to The Shepherd Farm, where lush greenery and refreshing
                            weather await you. Nestled in a picturesque countryside, our farm
                            offers a tranquil retreat from the hustle and bustle of city life.
                        </div>
                        <div className="mt-6 text-xl font-medium leading-8 max-md:max-w-full">
                            Stroll through our verdant pastures and enjoy the sight of our happy
                            goats grazing in the open fields. The farm’s serene environment,
                            coupled with the crisp, clean air, provides the perfect backdrop for
                            a relaxing day out with family and friends.
                            <br/>
                            Indulge in our farm-fresh goat milk ice cream, handcrafted from the
                            finest local ingredients. Whether you prefer the original or
                            chocolate flavor, our ice cream is a delightful treat that
                            complements the natural beauty surrounding you.
                            <br/>
                            <br/>
                            Come and experience the perfect blend of nature and flavor at The
                            Shepherd Farm. Let the green landscapes and pleasant weather
                            rejuvenate your spirit while you savor our delicious offerings. We
                            look forward to welcoming you to a memorable and refreshing visit!
                            <br/>
                            <br/>
                            Discover the charm of The Shepherd Farm and enjoy the serene beauty
                            and great weather that make our farm a perfect getaway.
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}


export default Event2

