import * as React from "react";
import { Container, IconButton } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import { styled } from "@mui/system";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import img from "../assets/images/WhatsApp_Image_2024-05-24_at_22.18.20_13c86cad.jpg";
import img1 from "../assets/images/images6.jpg";
import img2 from "../assets/images/images9.jpg";
import img3 from "../assets/images/images.jpg";
import img4 from "../assets/images/images3.jpg";
import img5 from "../assets/images/images7.jpg";
// import img6 from "../assets/images/images8.jpg";
import img7 from "../assets/images/images10.jpg";
import img8 from "../assets/images/images11.jpg";
import img9 from "../assets/images/images12.jpg";
import img10 from "../assets/images/images13.jpg";
import img11 from "../assets/images/images14.jpg";
import img12 from "../assets/images/images15.jpg";
import img13 from "../assets/images/images16.jpg";
import img14 from "../assets/images/images17.jpg";
// import img15 from "../assets/images/images18.jpg";
import img16 from "../assets/images/images19.jpg";
import Layout from "../layout/Layout";

const images = [
    { src: img, alt: "WhatsApp_Image_2024-05-24_at_22.18.20_13c86cad" },
    { src: img1, alt: "images 1" },
    { src: img2, alt: "images 2" },
    // { src: img3, alt: "images 3" },
    { src: img4, alt: "images 4" },
    { src: img5, alt: "images 5" },
    // { src: img6, alt: "images 6" },
    { src: img7, alt: "images 10" },
    { src: img8, alt: "images 11" },
    { src: img9, alt: "images 12" },
    { src: img10, alt: "images 13" },
    { src: img11, alt: "images 14" },
    { src: img12, alt: "images 15" },
    { src: img13, alt: "images 16" },
    { src: img14, alt: "images 17" },
    // { src: img15, alt: "images 18" },
    { src: img16, alt: "images 19" },
];

const ImageSlider = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Slide = styled('div')(({ theme }) => ({
    display: 'none',
    width: '100%',
    '&.active': {
        display: 'block',
    },
}));

const Home = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <Layout>
            <div className="flex flex-col justify-center bg-white">
                <Container>
                    <ImageSlider>
                        <IconButton
                            onClick={handlePrev}
                            sx={{ position: 'absolute', left: 0, zIndex: 1 }}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <ImageList
                            sx={{
                                width: '100%',
                                display: 'flex',
                                overflow: 'hidden',
                            }}
                        >
                            {images.map((image, index) => (
                                <Slide key={index} className={index === currentIndex ? 'active' : ''}>
                                    <ImageListItem>
                                        <img src={image.src} alt={image.alt} className="mt-7 w-full aspect-[1.54] max-md:max-w-full" />
                                    </ImageListItem>
                                </Slide>
                            ))}
                        </ImageList>
                        <IconButton
                            onClick={handleNext}
                            sx={{ position: 'absolute', right: 0, zIndex: 1 }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </ImageSlider>
                </Container>
            </div>
        </Layout>
    );
}

export default Home;
