import { Box } from "@mui/material";
import Header from "../components/Header";
import LineChart from "../components/LineChart";
import React from 'react';
import Layout from '../pages/global/Layout';

const Line = () => {
  return (
    <Layout>
      <div>
    <Box m="20px">
      <Header title="Line Chart" subtitle="Simple Line Chart" />
      <Box height="75vh">
        <LineChart />
      </Box>
    </Box>
    </div>
    </Layout>
  );
};

export default Line;
