import React, { useEffect, useState } from 'react';
import { API, Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports'; // Adjust the path as needed
import { listBookings } from '../../graphql/queries'; // Ensure the path is correct
import { ResponsivePie } from '@nivo/pie';
import { useTheme, FormControl, Box, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { tokens } from "../theme";
import moment from 'moment';

Amplify.configure(awsconfig);

const PieChart = ({ isDashboard = false, initialStartDate, initialEndDate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userData, setUserData] = useState([]);
  const [startDate, setStartDate] = useState(initialStartDate || moment().startOf('month'));
  const [endDate, setEndDate] = useState(initialEndDate || moment().endOf('month'));

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingResponse = await API.graphql({ query: listBookings });

        if (bookingResponse.data && bookingResponse.data.listBookings && bookingResponse.data.listBookings.items) {
          const bookings = bookingResponse.data.listBookings.items;

          // Filter bookings based on the selected date range
          const filteredBookings = bookings.filter(booking => {
            const bookingDate = moment(booking.date); // Adjust if your booking date field has a different name
            return bookingDate.isBetween(startDate, endDate, null, '[]');
          });

          // Convert booking data to pie chart format
          const data = filteredBookings.reduce((acc, booking) => {
            acc.tpAdult += booking.tpAdult || 0;
            acc.tpChildBelowThree += booking.tpChildBelowThree || 0;
            acc.tpChildBelowTwelve += booking.tpChildBelowTwelve || 0;
            acc.tpSeniorCitizen += booking.tpSeniorCitizen || 0;
            return acc;
          }, {
            tpAdult: 0,
            tpChildBelowThree: 0,
            tpChildBelowTwelve: 0,
            tpSeniorCitizen: 0,
          });

          const formattedData = [
            { id: 'Adults', label: 'Adults', value: data.tpAdult },
            { id: 'Children below three', label: 'Children < 3', value: data.tpChildBelowThree },
            { id: 'Children below twelve', label: 'Children < 12', value: data.tpChildBelowTwelve },
            { id: 'Senior Citizens', label: 'Senior Citizens', value: data.tpSeniorCitizen },
          ];

          setUserData(formattedData);
        } else {
          console.error('No bookings found.');
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, [startDate, endDate]);

  return (
    <div>
      {!isDashboard && (
        <Box display="flex" justifyContent="space-between" marginBottom="20px">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      )}
      <div style={{ height: isDashboard ? '250px' : '400px' }}>
        <ResponsivePie
          data={userData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
          }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={colors.grey[100]}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={false}
          arcLabelsRadiusOffset={0.4}
          arcLabelsSkipAngle={7}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PieChart;
