import * as React from "react";
import Layout from "../../layout/Layout";
import event4Image from "../../assets/images/event4.jpg";

const Event4 = () => {
    return (
        <Layout>
            <div className="flex flex-col bg-white">
                <img
                    loading="lazy"
                    src={event4Image}
                    className="self-center mt-20 w-full shadow-lg aspect-[1.49] max-w-[1200px] max-md:mt-10 max-md:max-w-full"
                />
                <div className="flex gap-5 justify-between self-center max-w-full w-[1071px] max-md:flex-wrap">
                    <div className="flex flex-col px-5 pt-20 pb-14 text-black max-md:max-w-full">
                        <div className="mt-6 text-6xl font-bold tracking-tighter max-md:max-w-full max-md:text-4xl">
                            Discover the Delight of Our Goat Milk and Ice Cream
                        </div>
                        <div className="mt-6 text-2xl leading-9 text-zinc-500 max-md:max-w-full">
                            Join us for a delightful experience at The Shepherd Farm, where you
                            can enjoy our farm-fresh goat milk ice cream. Handcrafted from the
                            finest local ingredients, our ice cream is a perfect treat for all
                            ages.
                        </div>
                        <div className="mt-6 text-xl font-medium leading-8 max-md:max-w-full">
                            Indulge in the rich and creamy flavors that only fresh goat milk can
                            offer. Our menu features a variety of delicious options, including
                            original and chocolate flavors, as well as goat milk yogurt and a
                            selection of refreshing beverages.
                            <br/>
                            <br/>
                            Whether you're here for a family outing or a quick getaway, The
                            Shepherd Farm promises a memorable and delicious adventure. Don't
                            miss out on trying our best-selling goat milk ice cream!
                            <br/>
                            <br/>
                            Visit us and taste the difference that fresh, local ingredients
                            make. We look forward to welcoming you to The Shepherd Farm!
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Event4;
