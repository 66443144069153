import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const WarningDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="warning-dialog-title"
    >
      <DialogTitle id="warning-dialog-title">Confirm Booking</DialogTitle>
      <DialogContent>
        <Typography>
        Please do not close or refresh the page while payment in processing. Kindly screenshot the booking details and WhatsApp to 016 831 2731 for further confirmation. Thank you
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
