import * as React from "react";
import Layout from "../layout/Layout";
import transportationCartoon from "../assets/images/transportationcartoon.jpg";

const Transportation = () => {
    return (
        <Layout>
            <div className="flex flex-col items-center bg-white">
                <img
                    src={transportationCartoon}
                    alt="Transportation cartoon"
                    className="mt-20 w-full shadow-lg aspect-[1.49] max-w-[1200px] max-md:mt-10 max-md:max-w-full"
                />
                <div className="mt-28 text-6xl font-bold tracking-tighter text-black w-[1114px] max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                    No Bus Allowed and Speed Limit in The Shepherd Farm
                </div>
                <div className="mt-6 text-2xl leading-9 text-zinc-500 w-[1114px] max-md:max-w-full">
                    To ensure the safety and well-being of our sheep, as well as the
                    enjoyment of all visitors, we have implemented specific restrictions and
                    guidelines for bus entry and speed limits within the sheep farm. Please
                    read and follow these guidelines carefully:
                </div>
                <div className="mt-6 text-xl font-medium leading-8 text-black w-[1114px] max-md:max-w-full">
                    Due to narrow roads within the farm, buses are unable to enter. Please
                    park buses in the designated parking area outside the farm. Visitors are
                    required to enter the farm on foot, and clearly marked walking paths are
                    provided to ensure safety and convenience. <br/>
                    <br/>
                    The speed limit within the sheep farm is strictly enforced at 10 km/h (6
                    mph). Drivers must adhere to the speed limit at all times to ensure the
                    safety of the sheep and pedestrians. Speeding or reckless driving will
                    result in immediate removal from the premises and potential bans on
                    future visits.
                    <br/>
                    <br/>
                    Drivers should be vigilant and yield to sheep and other animals that may
                    cross the roads within the farm. Noise levels should be kept to a
                    minimum to avoid disturbing the sheep. All visitors must remain within
                    designated areas and follow any additional instructions provided by farm
                    staff. By following these guidelines, we can maintain a safe and
                    peaceful environment for our sheep and an enjoyable experience for our
                    visitors. Thank you for your cooperation.
                </div>
            </div>
        </Layout>
    );
}

export default Transportation;