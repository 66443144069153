import * as React from "react";
import Layout from "../../layout/Layout";
import event1Image from "../../assets/images/event1.jpg";

const Event1 = () => {
    return (
        <Layout>
            <div className="flex flex-col bg-white">
                <img
                    loading="lazy"
                    src={event1Image}
                    className="self-center mt-20 w-full shadow-lg aspect-[1.49] max-w-[1200px] max-md:mt-10 max-md:max-w-full"
                />
                <div
                    className="flex gap-5 justify-between self-center mt-28 max-w-full w-[1056px] max-md:flex-wrap max-md:mt-10">
                    <div className="flex flex-col px-5 text-black max-md:max-w-full">
                        <div className="text-6xl font-bold tracking-tighter max-md:max-w-full max-md:text-4xl">
                            {" "}
                            Meet, Feed, and Enjoy Our Variety of Goats
                        </div>
                        <div className="mt-16 text-2xl leading-9 text-zinc-500 max-md:mt-10 max-md:max-w-full">
                            Plan a visit to The Shepherd Farm and immerse yourself in a
                            delightful experience surrounded by nature and our friendly goats.
                            Our farm is home to a diverse range of goat breeds, each with its
                            own unique charm and personality.
                        </div>
                        <div className="mt-9 text-xl font-medium leading-8 max-md:max-w-full">
                            Take a leisurely stroll through our well-maintained pastures, where
                            you can meet and interact with our goats. Whether you are a goat
                            enthusiast or just looking for a fun family outing, our farm offers
                            an engaging and educational experience for visitors of all ages.
                            <br/>
                            <br/>
                            One of the highlights of your visit will be the opportunity to feed
                            the goats. Enjoy the hands-on experience of giving treats to our
                            playful and curious goats, and watch their excitement as they come
                            to greet you. It's a perfect activity for children and adults alike,
                            creating wonderful memories and photo opportunities.
                            <br/>
                            In addition to meeting our goats, you can also explore our farm's
                            scenic surroundings. The lush greenery and open spaces provide a
                            peaceful environment to relax and unwind. Don't forget to try our
                            delicious farm-fresh goat milk ice cream, a treat that perfectly
                            complements your visit.
                            <br/>
                            <br/>
                            At The Shepherd Farm, we are dedicated to providing an enjoyable and
                            memorable experience for all our guests. Come and discover the joy
                            of spending time with our adorable goats, learning about their care,
                            and experiencing the beauty of farm life.
                            <br/>
                            We look forward to welcoming you to The Shepherd Farm, where you can
                            connect with nature, enjoy the company of our goats, and make
                            lasting memories.
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    );
}


export default Event1