import * as React from 'react';
import { Typography } from '@mui/material';

const TermsAndConditionsContent = () => {
    return (
        <Typography variant="body2" component="div">
            <p className="text-red-500 font-bold">‼ Please make your online transfer payment upon your registration to confirm your registration.</p>
            <p className="text-red-500 font-bold">🚫 NO REFUNDS AND CANCELLATIONS ONCE THE PAYMENT HAS BEEN MADE.</p>
            <p className="text-blue-500 font-bold">⭕ Postpone the date of visit is allowed if visitors unable to come. (Within the current year)</p>
            <Typography component="ul" sx={{ paddingLeft: '1.5em', listStyleType: 'disc' }}>
                <li>Each ticket is for one-time admission of one person to The Shepherd Farm once purchased.</li>
                <li>Visitors must check-in at Shepherd's Corner once arriving at the farm.</li>
                <li>Visitors have to follow the time of reservation to visit the farm.</li>
                <li>Admission is subject to The Shepherd Farm operating hours (which may change from time to time without prior notice) and capacity limitation.</li>
                <li>The ticket is non-refundable, not for resale. Postpone of the date of visitation is allowed twice with prior notice.</li>
                <li>The management does not entertain any refunds due to bad weather (heavy raining, lightning, etc.).</li>
                <li>Children below the age of 10 must be accompanied by an adult at all times when inside The Shepherd Farm.</li>
                <li>Children below the age of 3 years will be allowed to The Shepherd Farm for free. The management may require accompanying adults to provide proof of identification of their children at any time during the visit to the farm.</li>
                <li>OKU or special needs individuals will be allowed to enter the farm for free.</li>
                <li>Everyone is to read the house rules and understand the nature of how this farm operates and abide by the rules & regulations of the place.</li>
                <li>Be aware of certain signages to guide you with more info / direction on where to go and how to care for the flora & fauna of the place.</li>
                <li>By entering the farm, visitors accept that they have a duty to take reasonable steps to ensure their own safety & security of their belongings. All visitors in and around should behave in a safe manner at all times and accompanying adults shall exercise reasonable steps in supervising the kids.</li>
                <li>These premises are private property, and the owner, the management of these premises reserves the right to refuse admission or entry to any person.</li>
            </Typography>
        </Typography>
    );
};

export default TermsAndConditionsContent;
