import * as React from "react";
import Layout from "../../layout/Layout";
import event3Image from "../../assets/images/event3.jpg";

const Event3 = () => {
    return (
        <Layout>
            <div className="flex flex-col bg-white">
                <img
                    loading="lazy"
                    src={event3Image}
                    className="self-center mt-20 w-full shadow-lg aspect-[1.49] max-w-[1200px] max-md:mt-10 max-md:max-w-full"
                />
                <div className="flex gap-5 justify-between self-center mt-0 max-w-full w-[1056px] max-md:flex-wrap">
                    <div className="flex flex-col px-5 pt-20 text-black max-md:max-w-full">
                        <div className="mt-6 text-6xl font-bold tracking-tighter max-md:max-w-full max-md:text-4xl">
                            Visit The Shepherd Farm to See the Cows
                        </div>
                        <div className="mt-6 text-2xl leading-9 text-zinc-500 max-md:max-w-full">
                            Come and experience a fun-filled day at our farm, where you can get
                            up close and personal with our friendly cows! Perfect for families
                            and children, our farm offers a unique opportunity to learn about
                            these gentle giants in a picturesque rural setting.
                        </div>
                        <div className="mt-6 text-xl font-medium leading-8 max-md:max-w-full">
                            During your visit to our farm, you will have the delightful
                            opportunity to get up close and personal with our friendly cows.
                            Children and adults alike will enjoy feeding and petting these
                            gentle giants, gaining insights into their daily routines, diet, and
                            care from our knowledgeable staff. Our interactive sessions are
                            designed to be educational and engaging, making learning about cows
                            and sustainable farming practices a fun and memorable experience.
                            <br/>
                            <br/>
                            In addition to meeting the cows, you can participate in a variety of
                            farm activities. Join our guided tours to discover fascinating facts
                            about cows and their vital role in agriculture. Watch milking
                            demonstrations, take a relaxing hayride through the picturesque
                            countryside, and enjoy other hands-on activities that showcase the
                            charm and importance of farm life. Our farm provides a perfect
                            setting for a day of exploration, learning, and enjoyment amidst the
                            beauty of nature.
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Event3


