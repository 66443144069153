import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { formatDate } from '@fullcalendar/core';
import Layout from '../pages/global/Layout';
import { Box, List, ListItem, ListItemText, Typography, useTheme, MenuItem, Select } from "@mui/material";
import Header from "../components/Header";
import { tokens } from "../theme";
import { API, graphqlOperation } from 'aws-amplify';
import { listCalendars } from '../../graphql/queries';
import { createCalendar, deleteCalendar } from '../../graphql/mutations';

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  
  const timeSlots = [
    { label: '9:00 - 10:30', start: '09:00:00', end: '10:30:00' },
    { label: '10:30 - 12:00', start: '10:30:00', end: '12:00:00' }
  ];

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const eventData = await API.graphql(graphqlOperation(listCalendars));
      const events = eventData.data.listCalendars.items;
      setCurrentEvents(events.map(event => ({
        id: event.id,
        title: event.description,
        start: `${event.date}T${event.time}`,
        end: calculateEndTime(event.date, event.time),
        allDay: false,
      })));
    } catch (error) {
      console.error('Error fetching events', error);
    }
  };

  const calculateEndTime = (date, startTime) => {
    const slot = timeSlots.find(slot => slot.start === startTime);
    return slot ? `${date}T${slot.end}` : `${date}T${startTime}`;
  };

  const handleDateClick = async (selected) => {
    const title = prompt("Please enter a new title for your event");

    if (title && selectedTime) {
      const calendarApi = selected.view.calendar;
      calendarApi.unselect();

      const newEvent = {
        date: selected.startStr,
        time: selectedTime,
        description: title,
        isAvailable: true,
      };

      try {
        const result = await API.graphql(graphqlOperation(createCalendar, { input: newEvent }));
        const createdEvent = result.data.createCalendar;
        setCurrentEvents([...currentEvents, {
          id: createdEvent.id,
          title: createdEvent.description,
          start: `${createdEvent.date}T${createdEvent.time}`,
          end: calculateEndTime(createdEvent.date, createdEvent.time),
          allDay: false,
        }]);
      } catch (error) {
        console.error('Error creating event', error);
      }
    } else {
      alert('Please select a time slot for your event.');
    }
  };

  const handleEventClick = async (selected) => {
    if (window.confirm(`Are you sure you want to delete the event '${selected.event.title}'`)) {
      try {
        await API.graphql(graphqlOperation(deleteCalendar, { input: { id: selected.event.id } }));
        setCurrentEvents(currentEvents.filter(event => event.id !== selected.event.id));
        selected.event.remove();
      } catch (error) {
        console.error('Error deleting event', error);
      }
    }
  };

  return (
    <Layout>
      <div>
        <Box m="20px">
          <Header title="Calendar" subtitle="Control Your Open Day Time Slot" />
          <Box display="flex" justifyContent="space-between">
            <Box
              flex="1 1 20%"
              backgroundColor={colors.primary[400]}
              p="15px"
              borderRadius="4px"
            >
              
              <Box mt="20px">
                <Typography variant="h6">Select Time Slot:</Typography>
                <Select
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>Select a time slot</MenuItem>
                  {timeSlots.map((slot) => (
                    <MenuItem key={slot.start} value={slot.start}>
                      {slot.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Typography variant="h5">Events</Typography>
              <List>
                {currentEvents.length > 0 ? (
                  currentEvents.map((event) => (
                    <ListItem
                      key={event.id}
                      sx={{
                        backgroundColor: colors.greenAccent[500],
                        margin: "10px 0",
                        borderRadius: "2px",
                      }}
                    >
                      <ListItemText
                        primary={event.title}
                        secondary={
                          <>
                            <Typography>
                              {`${formatDate(event.start, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })} - ${formatDate(event.end, {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}`}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography>No events found</Typography>
                )}
              </List>
            </Box>
            <Box flex="1 1 100%" ml="15px">
              <FullCalendar
                height="75vh"
                plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                events={currentEvents}
                eventsSet={(events) => console.log('Events set:', events)}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default Calendar;
