import React from 'react';
import { Checkbox, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TermsAndConditionsContent from './common/TermsAndConditionsContent';

const TermsAndConditions = ({ checked, onChange }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box display="flex" alignItems="center">
            <InfoIcon sx={{ ml: 0.5, color: 'dodgerblue' }} />
                <Checkbox checked={checked} onChange={onChange} />
                <Typography display="flex" alignItems="center">
                    I agree to the{' '}
                    <Button variant="text" onClick={handleClickOpen} sx={{ padding: 0, textTransform: 'none', display: 'flex', alignItems: 'center' }}>
                        Terms & Conditions
                    </Button>
                </Typography>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Terms & Conditions</DialogTitle>
                <DialogContent>
                    <TermsAndConditionsContent />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{
                        bgcolor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        '&:hover': {
                            bgcolor: 'black',
                            color: 'white',
                        }
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TermsAndConditions;
