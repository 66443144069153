import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TimeSlotSelector = ({ time, timeSlot, handleTimeChange }) => {
  return (
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="time-slot-label">Select Time Slot</InputLabel>
        <Select
          labelId="time-slot-label"
          id="time-slot"
          value={time}
          onChange={handleTimeChange}
          label="Select Time Slot"
        >
          {timeSlot.map((slot, index) => (
            <MenuItem key={index} value={slot}>
              {slot}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default TimeSlotSelector;
