import {Container, Grid} from "@mui/material";
import Layout from "../layout/Layout";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import img1 from "../assets/images/event1.jpg"
import img2 from "../assets/images/event2.jpg"
import img3 from "../assets/images/event3.jpg"
import img4 from "../assets/images/event4.jpg"
import {useNavigate} from "react-router-dom";

const events = [
    {
        id: 1,
        name: "Meet, Feed, and Enjoy Our Variety of Goats",
        route: "/event/1",
        description: "Plan a visit to The Shepherd Farm and immerse yourself in a delightful experience surrounded by nature and our friendly goats. Our farm is home to a diverse range of goat breeds, each with its own unique charm and personality.",
        image: img1
    },
    {
        id: 2,
        name: "Experience the Green Serenity and Delightful Weather",
        route: "/event/2",
        description: "Escape to The Shepherd Farm, where lush greenery and refreshing weather await you. Nestled in a picturesque countryside, our farm offers a tranquil retreat from the hustle and bustle of city life.",
        image: img2
    },
    {
        id: 3,
        name: "Visit The Shepherd Farm to See the Cows",
        route: "/event/3",
        description: "Come and experience a fun-filled day at our farm, where you can get up close and personal with our friendly cows! Perfect for families and children, our farm offers a unique opportunity to learn about these gentle giants in a picturesque rural setting.",
        image: img3
    },
    {
        id: 4,
        name: "Discover the Delight of Our Goat Milk and Ice Cream",
        route: "/event/4",
        description: "Join us for a delightful experience at The Shepherd Farm, where you can enjoy our farm-fresh goat milk ice cream. Handcrafted from the finest local ingredients, our ice cream is a perfect treat for all ages.",
        image: img4
    },
]
const Event = () => {
    const navigate = useNavigate()
    return (
        <Layout>
            <Container sx={{mt:5}}>
                <Grid container spacing={2}>
                    {
                        events.map((r, i) => (
                            <Grid key={i} item sm={6} xs={12}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 350 }}
                                        image={r.image}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {r.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {
                                                r.description
                                            }
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {/*<Button size="small">View</Button>*/}
                                        <Button size="small" onClick={()=>navigate(r.route)}>Read More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }

                </Grid>

            </Container>
        </Layout>
    )
}

export default Event