import React, { useContext } from "react";
import { Box, IconButton, useTheme, InputBase } from "@mui/material";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const handleIconClick = (icon) => {
    alert(`${icon} icon clicked!`); // Placeholder for actual function
  };

  const topbarStyle = {
    height: '60px',
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#ffffff', 
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    position: 'fixed',
    top: 0,
    left: '250px',
    right: 0,
    zIndex: 1000,
  };

  return (
    <Box sx={topbarStyle}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={theme.palette.mode === 'dark' ? '#666' : '#f0f0f0'}
        borderRadius="4px"
        sx={{ flex: 1, ml: 2 }}
      >
        <InputBase sx={{ ml: 2, flex: 1, color: theme.palette.mode === 'dark' ? '#ffffff' : '#333' }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex" alignItems="center" sx={{ gap: '10px' }}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={() => handleIconClick('Notifications')}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => handleIconClick('Settings')}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => handleIconClick('Person')}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
