import React, { useEffect, useState, useRef } from 'react';
import { API, Auth, Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports'; // Adjust the path as needed
import { listBookings, listUsers, listCalendars } from '../../graphql/queries'; // Ensure the path is correct
import { deleteBooking, updateBooking } from '../../graphql/mutations'; // Ensure the path is correct
import { TableFooter, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, Checkbox, Box, Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, FormControl, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../components/Header";
import Layout from './global/Layout';
import dayjs from 'dayjs';

Amplify.configure(awsconfig);

const BookList = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);
    const [selected, setSelected] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedtimeslot, setSelectedTimeslot] = useState('');
    const [open, setOpen] = useState(false);
    const [currentBooking, setCurrentBooking] = useState(null);
    const [editDate, setEditDate] = useState("");
    const [editTime, setEditTime] = useState("");
    const [editStatus, setEditStatus] = useState("");
    const [isPrinting, setIsPrinting] = useState(false);
    const printRef = useRef(null);

    // Define status options
    const statusOptions = ['PENDING'];

    useEffect(() => {
        const fetchBookingsAndCalendars = async () => {
            try {
                console.log('Fetching bookings...');
                const bookingData = await API.graphql({ query: listBookings });
                console.log('Booking data:', bookingData);

                if (bookingData.data && bookingData.data.listBookings && bookingData.data.listBookings.items) {
                    const bookings = bookingData.data.listBookings.items;

                    console.log('Fetching users...');
                    const allUsers = await fetchAllUsers();
                    console.log('User data:', allUsers);

                    console.log('Fetching calendars...');
                    const calendarData = await API.graphql({ query: listCalendars });
                    console.log('Calendar data:', calendarData);

                    if (allUsers.length > 0 && calendarData.data && calendarData.data.listCalendars && calendarData.data.listCalendars.items) {
                        const usersMap = allUsers.reduce((acc, user) => {
                            acc[user.id] = {
                                name: `${user.firstName} ${user.lastName}`,
                                email: user.email,
                            };
                            return acc;
                        }, {});
                        console.log('Users Map:', usersMap);

                        const calendars = calendarData.data.listCalendars.items;
                        const calendarEvents = new Map();

                        // Create a map where key is the date and value is a set of times
                        calendars.forEach(calendar => {
                            const date = calendar.date;
                            const startTime = dayjs(calendar.time, 'HH:mm');
                            const endTime = startTime.add(1.5, 'hour');
                            const timeRange = `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
                            if (!calendarEvents.has(date)) {
                                calendarEvents.set(date, new Set());
                            }
                            calendarEvents.get(date).add(timeRange);
                        });

                        const bookingsList = bookings.filter(booking => {
                            const date = booking.date;
                            const timeRange = booking.time;
                            return calendarEvents.has(date) && calendarEvents.get(date).has(timeRange);
                        }).map(booking => {
                            console.log('Booking Owner:', booking.owner);
                            const user = usersMap[booking.owner] || { name: 'Unknown User', email: 'Unknown Email' };
                            console.log('Mapped User:', user);

                            return {
                                id: booking.id,
                                date: booking.date,
                                time: booking.time,
                                organisationName: booking.organisationName,
                                phoneNumber: booking.phoneNumber,
                                email: user.email,
                                tpAdult: booking.tpAdult,
                                tpChildBelowThree: booking.tpChildBelowThree,
                                tpChildBelowTwelve: booking.tpChildBelowTwelve,
                                tpSeniorCitizen: booking.tpSeniorCitizen,
                                pbGoatMilkOrigin: booking.pbGoatMilkOrigin,
                                pbGoatMilkChoco: booking.pbGoatMilkChoco,
                                pbGoatMilkCaramel: booking.pbGoatMilkCaramel,
                                total: booking.total,
                                owner: booking.owner,
                                fullName: user.name,
                                createdAt: booking.createdAt,
                            };
                        });

                        setRows(bookingsList);
                    } else {
                        console.error('No users or calendar events found.');
                    }
                } else {
                    console.error('No bookings found.');
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching bookings and calendar events:', error);
                setLoading(false);
            }
        };

        // Function to fetch all users with pagination
        const fetchAllUsers = async () => {
            let users = [];
            let nextToken = null;

            do {
                const userData = await API.graphql({
                    query: listUsers,
                    variables: { nextToken }
                });

                users = users.concat(userData.data.listUsers.items);
                nextToken = userData.data.listUsers.nextToken;
            } while (nextToken);

            return users;
        };

        fetchBookingsAndCalendars();
    }, []);    

    const handlePrint = () => {
        setIsPrinting(true);
        setTimeout(() => {
            if (printRef.current) {
                const printContents = printRef.current.innerHTML;
                const originalContents = document.body.innerHTML;
                document.body.innerHTML = `<div>${printContents}</div>`;
                window.print();
                document.body.innerHTML = originalContents;
                window.location.reload(); // Reload page to restore original contents
            } else {
                console.error("Print reference is null");
            }
            setIsPrinting(false);
        }, 1000);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // If a special value for "all rows" is chosen, set rowsPerPage to a large number or -1
        const newRowsPerPage = event.target.value === "all" ? -1 : parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };    

    const handleDelete = async () => {
        const confirmed = window.confirm("Are you sure you want to delete the selected bookings?");
        if (!confirmed) {
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();

            const deletionPromises = selected.map(async (id) => {
                await API.graphql({
                    query: deleteBooking,
                    variables: { input: { id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
            });

            await Promise.all(deletionPromises);
            setRows(rows.filter(row => !selected.includes(row.id)));
            setSelected([]);
        } catch (error) {
            console.error('Error deleting booking:', error);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };
    
    const handleTimeslotChange = (event) => {
        setSelectedTimeslot(event.target.value);
    };

    // Function to open the edit dialog
    const handleEditClick = (booking) => {
        setCurrentBooking(booking);
        setEditDate(booking.date);
        setEditTime(booking.time);
        setEditStatus(booking.status);
        setOpen(true);
    };

    // Function to close the edit dialog
    const handleClose = () => {
        setOpen(false);
        setCurrentBooking(null);
    };

    // Function to save the changes
    const handleSave = async () => {
        try {
            const updatedBooking = {
                id: currentBooking.id,
                date: editDate,
                time: editTime,
                status: editStatus,
            };

            const response = await API.graphql({
                query: updateBooking,
                variables: { input: updatedBooking },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            const updatedRows = rows.map(row => (row.id === currentBooking.id ? { ...row, ...updatedBooking } : row));
            setRows(updatedRows);
            handleClose();
        } catch (error) {
            console.error('Error updating booking:', error);
        }
    };

    // Filter rows based on selected year, month, and day
    const filteredRows = rows.filter(row => {
        const rowDate = dayjs(row.date, 'YYYY-MM-DD');
        const yearMatch = selectedYear ? rowDate.year() === parseInt(selectedYear) : true;
        const monthMatch = selectedMonth ? rowDate.month() === parseInt(selectedMonth) - 1 : true;
        const dayMatch = selectedDay ? rowDate.date() === parseInt(selectedDay) : true;
        const timeslotMatch = selectedtimeslot ? row.time === selectedtimeslot : true;
        return yearMatch && monthMatch && dayMatch && timeslotMatch;
    });  

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

    const calculateTotal = (filteredRows) => {
        const totals = {
            tpAdult: 0,
            tpChildBelowThree: 0,
            tpChildBelowTwelve: 0,
            tpSeniorCitizen: 0,
            pbGoatMilkOrigin: 0,
            pbGoatMilkChoco: 0,
            pbGoatMilkCaramel: 0,
            total: 0,
        };
    
        filteredRows.forEach(row => {
            totals.tpAdult += row.tpAdult || 0;
            totals.tpChildBelowThree += row.tpChildBelowThree || 0;
            totals.tpChildBelowTwelve += row.tpChildBelowTwelve || 0;
            totals.tpSeniorCitizen += row.tpSeniorCitizen || 0;
            totals.pbGoatMilkOrigin += row.pbGoatMilkOrigin || 0;
            totals.pbGoatMilkChoco += row.pbGoatMilkChoco || 0;
            totals.pbGoatMilkCaramel += row.pbGoatMilkCaramel || 0;
            totals.total += row.total || 0;
        });
    
        return totals;
    };
    
    const totals = calculateTotal(filteredRows);
    
    // Generate a list of days based on the selected month and year
    const generateDays = (year, month) => {
        const daysInMonth = new Date(year, month, 0).getDate(); // Get the number of days in the month
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(i.toString().padStart(2, '0')); // Pad single-digit days with a leading zero
        }
        return days;
    };

    return (
        <Layout>
            <Header title="Booking List" />
            <Box sx={{ p: 2 }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-day-label">Day</InputLabel>
                    <Select
                        labelId="select-day-label"
                        id="select-day"
                        value={selectedDay}
                        label="Day"
                        onChange={handleDayChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-month-label">Month</InputLabel>
                    <Select
                        labelId="select-month-label"
                        id="select-month"
                        value={selectedMonth}
                        label="Month"
                        onChange={handleMonthChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {[...Array(12).keys()].map(month => (
                            <MenuItem key={month + 1} value={month + 1}>{dayjs().month(month).format('MMMM')}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-year-label">Year</InputLabel>
                    <Select
                        labelId="select-year-label"
                        id="select-year"
                        value={selectedYear}
                        label="Year"
                        onChange={handleYearChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {[...Array(3).keys()].map(year => (
                            <MenuItem key={year} value={dayjs().year() + year}>{dayjs().year() + year}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-timeslot-label">Time Slot</InputLabel>
                    <Select
                        labelId="select-timeslot-label"
                        id="select-timeslot"
                        value={selectedtimeslot}
                        label="Time Slot"
                        onChange={handleTimeslotChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="09:00 - 10:30">09:00 - 10:30</MenuItem>
                        <MenuItem value="10:30 - 12:00">10:30 - 12:00</MenuItem>
                    </Select>
                </FormControl>

                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Button onClick={handlePrint} variant="contained" color="primary" disabled={isPrinting}>
                            {isPrinting ? 'Printing...' : 'Print'}
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleDelete} variant="contained" color="secondary" disabled={selected.length === 0}>
                            Delete Selected
                        </Button>
                    </div>
                </Box>
            </Box>

            <TableContainer component={Paper} ref={printRef}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={rows.length > 0 && selected.length === rows.length}
                                    onChange={handleSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all bookings' }}
                                />
                            </TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Organisation</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Total Adults</TableCell>
                            <TableCell>Total Children Below Three</TableCell>
                            <TableCell>Total Children Below Twelve</TableCell>
                            <TableCell>Total Senior Citizen</TableCell>
                            <TableCell>Goat Milk (Original)</TableCell>
                            <TableCell>Goat Milk (Choco)</TableCell>
                            <TableCell>Goat Milk (Caramel)</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={isItemSelected} />
                                    </TableCell>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell>{row.fullName}</TableCell>
                                    <TableCell>{row.time}</TableCell>
                                    <TableCell>{row.organisationName}</TableCell>
                                    <TableCell>{row.phoneNumber}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.tpAdult}</TableCell>
                                    <TableCell>{row.tpChildBelowThree}</TableCell>
                                    <TableCell>{row.tpChildBelowTwelve}</TableCell>
                                    <TableCell>{row.tpSeniorCitizen}</TableCell>
                                    <TableCell>{row.pbGoatMilkOrigin}</TableCell>
                                    <TableCell>{row.pbGoatMilkChoco}</TableCell>
                                    <TableCell>{row.pbGoatMilkCaramel}</TableCell>
                                    <TableCell>{row.total}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7}>Totals</TableCell>
                            <TableCell>{totals.tpAdult}</TableCell>
                            <TableCell>{totals.tpChildBelowThree}</TableCell>
                            <TableCell>{totals.tpChildBelowTwelve}</TableCell>
                            <TableCell>{totals.tpSeniorCitizen}</TableCell>
                            <TableCell>{totals.pbGoatMilkOrigin}</TableCell>
                            <TableCell>{totals.pbGoatMilkChoco}</TableCell>
                            <TableCell>{totals.pbGoatMilkCaramel}</TableCell>
                            <TableCell>{totals.total}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: 'all' }]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Booking</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To edit this booking, please modify the fields below and click Save.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        label="Date"
                        type="date"
                        fullWidth
                        value={editDate}
                        onChange={(e) => setEditDate(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Time"
                        type="text"
                        fullWidth
                        value={editTime}
                        onChange={(e) => setEditTime(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Status"
                        select
                        fullWidth
                        value={editStatus}
                        onChange={(e) => setEditStatus(e.target.value)}
                    >
                        {statusOptions.map((status) => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default BookList;
