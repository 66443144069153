import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stepper, Step, StepLabel, Typography } from '@mui/material';
import OpenDayCalendar from '../assets/images/OpenDayCalendar.jpg'
import SelectDate from '../assets/images/SelectDate.jpg'
import Appointment from '../assets/images/Appointment.jpg'
import Rules from '../assets/images/Rules.jpg'
import Book from '../assets/images/Book.jpg'

const steps = [
  {
    label: 'Open Day Calendar',
    content: 'This calendar is use for checking open date and time slot for farm : ',
    image: OpenDayCalendar // 替换为实际图片路径
  },
  {
    label: 'Select Date',
    content: 'Use here to choose the date then it will pop out the time slot to choose',
    image: SelectDate // 替换为实际图片路径
  },
  {
    label: 'Appointment',
    content: 'If you want to book with the special time out of the farm’s designated open day, special appointment can be book by click on this button.',
    image: Appointment // 替换为实际图片路径
  },
  {
    label: 'Rules',
    content: 'The total amount must more than 0 so that you can make booking or appointment.',
    image: Rules // 替换为实际图片路径
  },
  {
    label: 'Refer Back',
    content: 'If you miss any information want refer back press the book now button again',
    image: Book // 替换为实际图片路径
  }
];

const TutorialDialog = ({ open, handleClose, handleComplete }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleComplete();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Tutorial: Intro function for booking page</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Typography variant="body1" mt={2}>{steps[activeStep].content}</Typography>
          <img src={steps[activeStep].image} alt={steps[activeStep].label} style={{ marginTop: '20px', width: '100%', height: 'auto' }} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TutorialDialog;

