import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';

const MilkPreBooking = ({ register, errors }) => (
  <Grid item xs={12}>
    <Typography variant="h6">Pre-booking Goat Milk</Typography>
    <Typography>Original: RM 12/btl (300ml)</Typography>
    <Typography>Chocolate Flav: RM 13/btl (300ml)</Typography>
    <Typography>Caramel Flav: RM 13/btl (300ml)</Typography>
    <Grid container spacing={2} mt={2}>
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          label="Goat Milk (Original)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("pbGoatMilkOrigin", {
            valueAsNumber: true,
            setValueAs: v => (v === '' || v === null ? 0 : parseInt(v, 10)),
            min: 0
          })}
          error={Boolean(errors.pbGoatMilkOrigin)}
          helperText={errors.pbGoatMilkOrigin?.message}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          label="Goat Milk (Chocolate)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("pbGoatMilkChoco", {
            valueAsNumber: true,
            setValueAs: v => (v === '' || v === null ? 0 : parseInt(v, 10)),
            min: 0
          })}
          error={Boolean(errors.pbGoatMilkChoco)}
          helperText={errors.pbGoatMilkChoco?.message}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          fullWidth
          label="Goat Milk (Caramel)"
          variant="outlined"
          type="number"
          defaultValue={0}
          inputProps={{ min: 0 }}
          {...register("pbGoatMilkCaramel", {
            valueAsNumber: true,
            setValueAs: v => (v === '' || v === null ? 0 : parseInt(v, 10)),
            min: 0
          })}
          error={Boolean(errors.pbGoatMilkCaramel)}
          helperText={errors.pbGoatMilkCaramel?.message}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default MilkPreBooking;