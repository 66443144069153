import { Box } from "@mui/material";
import Header from "../components/Header";
import PieChart from "../components/PieChart";
import React from 'react';
import Layout from '../pages/global/Layout';

const Pie = () => {
  return (
    <Layout>
      <div>
    <Box m="20px">
      <Header title="Pie Chart" subtitle="Total Pax" />
      <Box height="75vh">
        <PieChart />
      </Box>
    </Box>
    </div>
    </Layout>
  );
};

export default Pie;
