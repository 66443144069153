/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      fullName
      userName
      phoneNumber
      organisationName
      booking {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        fullName
        userName
        phoneNumber
        organisationName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      date
      time
      organisationName
      phoneNumber
      tpAdult
      tpChildBelowThree
      tpChildBelowTwelve
      tpSeniorCitizen
      pbGoatMilk
      pbGoatMilkOrigin
      pbGoatMilkChoco
      pbGoatMilkCaramel
      total
      userID
      user {
        id
        firstName
        lastName
        email
        fullName
        userName
        phoneNumber
        organisationName
        createdAt
        updatedAt
        owner
        __typename
      }
      appointmentID
      createdAt
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        time
        organisationName
        phoneNumber
        tpAdult
        tpChildBelowThree
        tpChildBelowTwelve
        tpSeniorCitizen
        pbGoatMilk
        pbGoatMilkOrigin
        pbGoatMilkChoco
        pbGoatMilkCaramel
        total
        userID
        appointmentID
        createdAt
        status
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byUserIDBooking = /* GraphQL */ `
  query ByUserIDBooking(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserIDBooking(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        organisationName
        phoneNumber
        tpAdult
        tpChildBelowThree
        tpChildBelowTwelve
        tpSeniorCitizen
        pbGoatMilk
        pbGoatMilkOrigin
        pbGoatMilkChoco
        pbGoatMilkCaramel
        total
        userID
        appointmentID
        createdAt
        status
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bookingsByAppointmentID = /* GraphQL */ `
  query BookingsByAppointmentID(
    $appointmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByAppointmentID(
      appointmentID: $appointmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        organisationName
        phoneNumber
        tpAdult
        tpChildBelowThree
        tpChildBelowTwelve
        tpSeniorCitizen
        pbGoatMilk
        pbGoatMilkOrigin
        pbGoatMilkChoco
        pbGoatMilkCaramel
        total
        userID
        appointmentID
        createdAt
        status
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      date
      time
      description
      isAvailable
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        time
        description
        isAvailable
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      userId
      adminId
      calendarId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        adminId
        calendarId
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentsByUserId = /* GraphQL */ `
  query AppointmentsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        adminId
        calendarId
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appointmentsByCalendarId = /* GraphQL */ `
  query AppointmentsByCalendarId(
    $calendarId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByCalendarId(
      calendarId: $calendarId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        adminId
        calendarId
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
