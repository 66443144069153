import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import 'react-calendar/dist/Calendar.css';
import TimeSlotSelector from './TimeSlotSelector'; // Adjust the import path as needed

const DatePickerComponent = ({
  value,
  setValue,
  handleDayClick,
  tileDisabled,
  handleEnableAllDatesClick,
  shouldDisableDate,
  dialogOpen,
  closeEventDialog,
  openDialog,
  closeWarningDialog,
  dialogContent = [], // Default to an empty array
  setDialogContent, // Ensure this prop is passed
  setDialogOpen, // Ensure this prop is passed
  bookingList,
  calendarList,
  time,
  setTime,
  timeSlotOptions,
  setTimeSlotOptions,
  defaultTimeSlots,
}) => {
  const maxCapacity = 70;
  const maxGoatMilkCapacity = 25;
  const [selectedDate, setSelectedDate] = useState(null);
  const [remainingSpots, setRemainingSpots] = useState({});
  const [remainingGoatMilkSpots, setRemainingGoatMilkSpots] = useState({});
  const [showTimeSlotSelector, setShowTimeSlotSelector] = useState(false); // New state to control TimeSlotSelector
  const [disableDates, setDisableDates] = useState(true); // State to control date disabling
  const [minDateOffset, setMinDateOffset] = useState(2); // State to control min date offset

  useEffect(() => {
    if (value) {
      const selectedBookingDate = dayjs(value).format('YYYY-MM-DD');
      const availableTimes = getAvailableTimes(dayjs(value));
      const spots = calculateRemainingSpots(selectedBookingDate, availableTimes);
      const GoatMilkspots = calculateRemainingGoatMilkSpots(selectedBookingDate, availableTimes);
      setRemainingSpots(spots);
      setRemainingGoatMilkSpots(GoatMilkspots);
      setTimeSlotOptions(availableTimes);
    }
  }, [value, bookingList]);

  const calculateRemainingSpots = (date, times) => {
    const spots = {};
    times.forEach(timeSlot => {
      const totalBookedInSlot = bookingList
        .filter(booking => booking.date === date && booking.time === timeSlot)
        .reduce((acc, booking) => acc + booking.tpAdult + booking.tpChildBelowThree + booking.tpChildBelowTwelve + booking.tpSeniorCitizen, 0);
      spots[timeSlot] = maxCapacity - totalBookedInSlot;
    });
    return spots;
  };

  const calculateRemainingGoatMilkSpots = (date, times) => {
    const GoatMilkspots = {};
    times.forEach(timeSlot => {
      const totalBookedInSlot = bookingList
        .filter(booking => booking.date === date && booking.time === timeSlot)
        .reduce((acc, booking) => acc + booking.pbGoatMilkOrigin + booking.pbGoatMilkChoco + booking.pbGoatMilkCaramel, 0);
      GoatMilkspots[timeSlot] = maxGoatMilkCapacity - totalBookedInSlot;
    });
    return GoatMilkspots;
  };

  const getAvailableTimes = (date) => {
    const dateStr = date.format('YYYY-MM-DD');
    const eventsOnDate = calendarList.filter(event => event.date === dateStr && event.description !== 'BOOKED');
    const times = eventsOnDate.map(event => {
      const startTime = dayjs(event.time, 'HH:mm');
      const endTime = startTime.add(1.5, 'hour');
      return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
    });
    return times.length ? times : defaultTimeSlots;
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleCalendarDayClick = (date) => {
    const dayjsDate = dayjs(date);
    setSelectedDate(dayjsDate);
    setDialogContent(calendarList.filter(event => event.date === dayjsDate.format('YYYY-MM-DD')));
    setDialogOpen(true);
  };

  const handleDateChange = (newValue) => {
    const dayjsDate = dayjs(newValue);
    setValue(dayjsDate);
    setSelectedDate(dayjsDate); // Reset selectedDate when value changes
    const availableTimes = getAvailableTimes(dayjsDate);
    const GoatMilkspots = calculateRemainingGoatMilkSpots(dayjsDate.format('YYYY-MM-DD'), availableTimes);
    const spots = calculateRemainingSpots(dayjsDate.format('YYYY-MM-DD'), availableTimes);
    setRemainingGoatMilkSpots(GoatMilkspots);
    setRemainingSpots(spots);
    setTimeSlotOptions(availableTimes); // 更新 timeSlotOptions
    setShowTimeSlotSelector(true); // Show TimeSlotSelector when DatePicker date is selected
  };

  const shouldDisableDateInternal = (date) => {
    const dateStr = dayjs(date).format('YYYY-MM-DD');
    const eventsOnDate = calendarList.filter(event => event.date === dateStr);
    
    if (disableDates) {
      // Only disable dates that are fully booked or meet the shouldDisableDate criteria
      return eventsOnDate.every(event => event.description === 'BOOKED') || shouldDisableDate(date);
    }

    // If disableDates is false, do not disable any dates except those already booked
    return eventsOnDate.some(event => event.description === 'BOOKED');
  };

  const handleEnableAllDatesClickInternal = () => {
    setDisableDates(false);
    handleEnableAllDatesClick(); // Call the external function if needed
  };

  const handleContinueClick = () => {
    setMinDateOffset(7); // Change the min date offset to 14 days after clicking continue
    closeWarningDialog(true); // Close the warning dialog
  };

  return (
    <Grid item xs={12}>
      <Box mt={4}>
        <Typography variant="h6">Open Day Calendar</Typography>
        <Calendar
          onClickDay={(date) => handleCalendarDayClick(date)}
          value={selectedDate ? selectedDate.toDate() : null}
          tileDisabled={tileDisabled}
        />
        <Dialog
          open={dialogOpen}
          onClose={closeEventDialog}
        >
          <DialogTitle>Events on {selectedDate ? selectedDate.format('MMMM D, YYYY') : ''}</DialogTitle>
          <DialogContent>
            {dialogContent.length > 0 ? (
              dialogContent.map(event => {
                const startTime = dayjs(event.time, 'HH:mm');
                const endTime = startTime.add(1.5, 'hour');
                return (
                  <Box key={event.id} mb={2}>
                    <Typography variant="h6">{event.title}</Typography>
                    <Typography>{event.description}</Typography>
                    <Typography>{`${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`}</Typography>
                  </Box>
                );
              })
            ) : (
              <Typography>No events for this day.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEventDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="body1" mt={1}>
          Customer Chosen Date: {value ? dayjs(value).format('MMMM D, YYYY') : ''}
        </Typography>
        {time && (
          <React.Fragment>
            <Typography variant="body1" mt={1}>
              Time Slot: {time}
            </Typography>
            <Typography variant="body1" mt={1}>
              Remaining Seats: {remainingSpots[time]}
            </Typography>
          </React.Fragment>
        )}
      </Box>
      <Typography variant="h6" mt={2}>Date</Typography>
      <p>"Please Choose Date Here Then You Can Choose Time Slot"</p><br></br>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <DatePicker
            label="Select Date"
            value={value}
            onChange={handleDateChange}
            shouldDisableDate={shouldDisableDateInternal}
            minDate={dayjs().add(minDateOffset, 'day')} // Set the min date dynamically
            fullWidth
          />
          <Box mt={2}>
            <Button
              onClick={handleEnableAllDatesClickInternal} // Use the internal handler
              variant="contained"
              style={{
                backgroundColor: '#bf3000',  
                color: '#f8f9fa',             
                borderRadius: 100,            
                padding: '10px 20px',         
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)', 
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: '#8a3300',
                },
              }}
            >
              If you want to book on an unspecified date, please Click Here!(min 25 person)
            </Button>
          </Box>
        </div>
      </LocalizationProvider>
      
      {showTimeSlotSelector && (
        <Box mt={2}>  {/* Add margin top to create space */}
          <TimeSlotSelector
            time={time}
            timeSlot={timeSlotOptions}
            handleTimeChange={handleTimeChange}
          />
        </Box>
      )}
      <Dialog
        open={openDialog}
        onClose={() => closeWarningDialog(false)}
      >
        <DialogTitle>{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          "If you don't book on the specified date, your booking will require a reply from the administrator and may face rejection. For appointment min person are 25, if less than 25 you cannot do appointment"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeWarningDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleContinueClick} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DatePickerComponent;