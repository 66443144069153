import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';

const OrganisationInfo = ({ register, errors }) => (
  <Grid item xs={12}>
    <Typography variant="h6">Organisation name (If applicable):</Typography>
    <TextField
      fullWidth
      variant="outlined"
      {...register("organisationName")}
      error={Boolean(errors.organisationName)}
      helperText={errors.organisationName?.message}
    />
  </Grid>
);

export default OrganisationInfo;
