import './App.css';
import "./input.css"
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Location from "./components/Location";
import Booking from "./components/bookingcomponent/BookingComp";
import Transportation from "./components/Transportaion";
import Event1 from "./components/events/Event1";
import Event2 from "./components/events/Event2";
import Event3 from "./components/events/Event3";
import Event4 from "./components/events/Event4";
import Event from "./components/Event";
import Topbar from "./admin/pages/global/Topbar";
import Sidebar from "./admin/pages/global/Sidebar";
import Dashboard from "./admin/pages/dashboard";
import BookList from "./admin/pages/BookList";
import Invoices from "./admin/pages/invoices";
import UserDetails from "./admin/pages/userdetails";
import Bar from "./admin/pages/bars";
import Form from "./admin/pages/form";
import Line from "./admin/pages/line";
import Pie from "./admin/pages/pie";
import Appointments from "./admin/pages/Appointments";
import Calendar from "./admin/pages/calendar";
import ProtectedRoute from './components/common/ProtectedRoute';
import TermsAndConditions from './components/TermsAndConditions';
import BookingDetails from './components/BookingDetails';
import AdmissionFee from './components/AdmissionFee';


function App() {
  return (
    <div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/location" element={<Location />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/transportation" element={<Transportation />} />
        <Route path="/event/1" element={<Event1 />} />
        <Route path="/event/2" element={<Event2 />} />
        <Route path="/event/3" element={<Event3 />} />
        <Route path="/event/4" element={<Event4 />} />
        <Route path="/event" element={<Event />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/BookingDetails" element={<BookingDetails />} />
        <Route path="/AdmissionFee" element={<AdmissionFee />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/pages/dashboard" element={<Dashboard />} />
          <Route path="/admin/pages/BookList" element={<BookList />} />
          <Route path="/admin/pages/userdetails" element={<UserDetails />} />
          <Route path="/admin/pages/invoices" element={<Invoices />} />
          <Route path="/admin/pages/form" element={<Form />} />
          <Route path="/admin/pages/bars" element={<Bar />} />
          <Route path="/admin/pages/pie" element={<Pie />} />
          <Route path="/admin/pages/line" element={<Line />} />
          <Route path="/admin/pages/Appointments" element={<Appointments />} />
          <Route path="/admin/pages/calendar" element={<Calendar />} />
          <Route path="/admin/pages/global/Topbar" element={<Topbar />} />
          <Route path="/admin/pages/global/Sidebar" element={<Sidebar />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
