import OtpInput from "react-otp-input";
import * as React from "react";
import {useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ErrorMessage} from "@hookform/error-message";
import Button from "@mui/material/Button";
import {Auth} from "aws-amplify";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";


const defaultTheme = createTheme();
const Confirmation = ({email, pass, handleClose}) => {
    const [items, setItems] = useState(["", "", "", "", "", ""]);
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(30);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [resendEnable, setResendEnabled] = useState(true);
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        setError,
        getValues,
    } = useForm();


    useEffect(() => {
        if (email) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    setResendEnabled(false);
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [seconds, email]);

    const onSubmit = async (data) => {
        try {
            await Auth.confirmSignUp(email, otp)
                .then(() => {
                    setVerificationSuccess(true);
                    setTimeout(async () => {
                        // props.changeState(0);
                        await Auth.signIn({
                            username: email,
                            password: pass,
                        }).then(async (r) => {
                            // let data = await currentUserData(r.username);
                            // await router.push("/home");

                            navigate("/booking")
                            handleClose()
                        });
                    }, 3000);
                })
                .catch((error) => {
                    if (error.code === "CodeMismatchException") {
                        setError(
                            "code",
                            {
                                type: "custom",
                                message: "OTP Does not match",
                            },
                            {shouldFocus: true}
                        );
                    }
                });
        } catch (e) {
            console.error(e);
        }
    };

    const handleChangeCode = (value) => {
        setOtp(value);
    };

    const resendOtp = async () => {
        try {
            await Auth.resendSignUp(email);
        } catch (error) {
            console.error("Error resending confirmation code:", error);
        }
    };
    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                        {/*    <LockOutlinedIcon />*/}
                        {/*</Avatar>*/}
                        <Typography component="h1" variant="h5">
                            Conformation Code
                        </Typography>
                        <Box mt={5}>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <OtpInput
                                    value={otp}
                                    onChange={handleChangeCode}
                                    numInputs={6}
                                    separator={<span style={{width: "8px"}}></span>}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        border: "1px solid black",
                                        borderRadius: "8px",
                                        width: "40px",
                                        height: "40px",
                                        fontSize: "17px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "black",
                                    }}
                                    focusStyle={{
                                        border: "1px solid #CFD3DB",
                                        outline: "none",
                                    }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({message}) => <p>{message}</p>}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Confirm
                                </Button>
                            </form>

                            <div className="col-12">
                                <div style={{textAlign: "center", marginTop: "10px"}}>
                                    Did not receive a code?
                                </div>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    {seconds === 0 ? (
                                        <Button
                                            style={{textDecoration: "underline"}}
                                            color="secondary"
                                            onClick={() => {
                                                setItems(["", "", "", "", "", ""]);
                                                resendOtp().then(() => setSeconds(60));
                                            }}
                                        >
                                            Resend Code
                                        </Button>
                                    ) : (
                                        <div>Try again after {seconds} seconds</div>
                                    )}
                                </div>
                            </div>
                        </Box>

                    </Box>
                </Container>
            </ThemeProvider>


        </>
    )
}

export default Confirmation