/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendBookingConfirmationEmail = /* GraphQL */ `
  mutation SendBookingConfirmationEmail(
    $email: String!
    $bookingDetails: String!
  ) {
    sendBookingConfirmationEmail(email: $email, bookingDetails: $bookingDetails)
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      paymentId
      amount
      currency
      email
      status
      paymentDetails
      paymentUrl
      createdAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      fullName
      userName
      phoneNumber
      organisationName
      booking {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      fullName
      userName
      phoneNumber
      organisationName
      booking {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      fullName
      userName
      phoneNumber
      organisationName
      booking {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      date
      time
      organisationName
      phoneNumber
      tpAdult
      tpChildBelowThree
      tpChildBelowTwelve
      tpSeniorCitizen
      pbGoatMilk
      pbGoatMilkOrigin
      pbGoatMilkChoco
      pbGoatMilkCaramel
      total
      userID
      user {
        id
        firstName
        lastName
        email
        fullName
        userName
        phoneNumber
        organisationName
        createdAt
        updatedAt
        owner
        __typename
      }
      appointmentID
      createdAt
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      date
      time
      organisationName
      phoneNumber
      tpAdult
      tpChildBelowThree
      tpChildBelowTwelve
      tpSeniorCitizen
      pbGoatMilk
      pbGoatMilkOrigin
      pbGoatMilkChoco
      pbGoatMilkCaramel
      total
      userID
      user {
        id
        firstName
        lastName
        email
        fullName
        userName
        phoneNumber
        organisationName
        createdAt
        updatedAt
        owner
        __typename
      }
      appointmentID
      createdAt
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      date
      time
      organisationName
      phoneNumber
      tpAdult
      tpChildBelowThree
      tpChildBelowTwelve
      tpSeniorCitizen
      pbGoatMilk
      pbGoatMilkOrigin
      pbGoatMilkChoco
      pbGoatMilkCaramel
      total
      userID
      user {
        id
        firstName
        lastName
        email
        fullName
        userName
        phoneNumber
        organisationName
        createdAt
        updatedAt
        owner
        __typename
      }
      appointmentID
      createdAt
      status
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      date
      time
      description
      isAvailable
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      date
      time
      description
      isAvailable
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      date
      time
      description
      isAvailable
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      userId
      adminId
      calendarId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      userId
      adminId
      calendarId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      userId
      adminId
      calendarId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
