import * as React from "react";
import Layout from "../layout/Layout";
import locationCartoon from "../assets/images/locationcartoon.jpg";

const Location = () => {
    return (
        <Layout>
            <div className="flex flex-col bg-white">
                <img
                    loading="lazy"
                    src={locationCartoon}
                    alt="Location cartoon"
                    className="mx-auto my-0 border border-black border-solid"
                    style={{maxWidth: '100%', height: 'auto'}}
                />
                <div className="z-10 self-center mt-3 w-full max-w-[1154px] max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3987.1997691264683!2d111.48323730000001!3d2.0759426999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMsKwMDQnMzMuNCJOIDExMcKwMjgnNTkuNyJF!5e0!3m2!1sen!2smy!4v1716730879327!5m2!1sen!2smy"
                                    width="600"
                                    height="450"
                                    style={{ border: 0, width: "100%", height: "450px" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow px-5 py-20 text-black max-md:mt-10 max-md:max-w-full">
                                <div className="mt-6 text-6xl font-bold tracking-tighter max-md:max-w-full max-md:text-4xl">
                                    The Location of The Shepherd Farm
                                </div>
                                <div className="mt-6 text-2xl leading-9 text-zinc-500 max-md:max-w-full">
                                    Jalan Merudu Meruton Sarikei, 96100 Sarikei, Sarawak
                                </div>
                                <div className="mt-6 text-xl font-medium leading-8 max-md:max-w-full">
                                    From there, you can easily navigate to our designated photo
                                    area, perfect for capturing memories with beautiful farm
                                    backdrops. The map clearly labels each zone with playful
                                    illustrations to guide you through the various attractions.
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Location;