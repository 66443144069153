import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                The Shepherd Farm
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignIn({ handleClose }) {
    const navigate = useNavigate();
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [step, setStep] = useState(1); // Step 1: Send Code, Step 2: Reset Password

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError
    } = useForm();

    const onSubmit = async (data) => {
        if (isForgotPassword) {
            if (step === 1) {
                try {
                    await Auth.forgotPassword(data.email);
                    setStep(2);
                } catch (error) {
                    console.error("forgot password:", error);
                    setError("email", {
                        type: "custom",
                        message: error.message,
                    });
                }
            } else {
                if (data.password !== data.confirmPassword) {
                    setError("confirmPassword", {
                        type: "custom",
                        message: "Passwords do not match",
                    });
                    return;
                }
                try {
                    await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
                    setIsForgotPassword(false);
                    setStep(1);
                    navigate("/", { state: { message: "Password changed successfully" } });
                    handleClose();
                } catch (error) {
                    console.error("forgot password submit:", error);
                    setError("code", {
                        type: "custom",
                        message: error.message,
                    });
                    setError("password", {
                        type: "custom",
                        message: error.message,
                    });
                }
            }
        } else {
            try {
                const user = await Auth.signIn(data.email, data.password);
                const session = await Auth.currentSession();
                const idToken = session.getIdToken();
                const groups = idToken.payload["cognito:groups"] || [];
                console.log("User groups:", groups);

                if (groups.includes("Employee") || groups.includes("Employer")) {
                    navigate("/admin/pages/dashboard");
                } else {
                    navigate("/");
                }
                handleClose();
            } catch (error) {
                console.error("sign in:", error.code);
                console.error("sign in:", error.message);
                if (error.code === "UserNotConfirmedException") {
                    console.error("Verification code resent successfully. Check your email.");
                }
                if (error.code === "UserNotFoundException") {
                    setError(
                        "email",
                        {
                            type: "custom",
                            message: error.message,
                        },
                        { shouldFocus: true }
                    );
                }
                if (error.code === "NotAuthorizedException") {
                    setError(
                        "email",
                        {
                            type: "custom",
                            message: error.message,
                        },
                        { shouldFocus: true }
                    );
                    setError(
                        "password",
                        {
                            type: "custom",
                            message: error.message,
                        },
                        { shouldFocus: true }
                    );
                }
            }
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {isForgotPassword ? 'Reset Password' : 'Welcome to The Shepherd Farm'}
                    </Typography>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                {...register("email", {
                                    required: { value: true, message: "Email is required" },
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Email address is not valid",
                                    },
                                })}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                            />
                            {isForgotPassword && step === 2 && (
                                <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="code"
                                        label="Verification Code"
                                        id="code"
                                        autoComplete="verification-code"
                                        {...register("code", {
                                            required: { value: true, message: "Verification code is required" },
                                        })}
                                        error={Boolean(errors.code)}
                                        helperText={errors.code?.message}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="New Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        {...register("password", {
                                            required: { value: true, message: "Password is required" },
                                            minLength: {
                                                value: 8,
                                                message: "Minimum length of password is 8",
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                                            },
                                        })}
                                        error={Boolean(errors.password)}
                                        helperText={errors.password?.message}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="new-password"
                                        {...register("confirmPassword", {
                                            required: { value: true, message: "Confirm Password is required" },
                                            validate: value =>
                                                value === watch('password') || "Passwords do not match"
                                        })}
                                        error={Boolean(errors.confirmPassword)}
                                        helperText={errors.confirmPassword?.message}
                                    />
                                </>
                            )}
                            {!isForgotPassword && (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    {...register("password", {
                                        required: { value: true, message: "Password is required" },
                                        minLength: {
                                            value: 8,
                                            message: "Minimum length of password is 8",
                                        },
                                    })}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                />
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {isForgotPassword ? (step === 1 ? 'Send Code' : 'Reset Password') : 'Sign In'}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={() => setIsForgotPassword(true)} variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                {/*<Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>*/}
                            </Grid>
                        </Box>
                    </form>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}