import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const ProtectedRoute = ({ redirectPath = '/' }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserGroup = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const groups = idToken.payload["cognito:groups"] || [];
        console.log("User groups:", groups); // Log the groups for debugging

        if (groups.includes("Employee") || groups.includes("Employer")) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        console.error("Error checking user session:", error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserGroup();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
