import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Collapse, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TermsAndConditionsContent from './TermsAndConditionsContent'; // 引入条款和条件组件
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import Slide from '@mui/material/Slide';
import SignIn from "./Login";
import SignUp from "./SignUp";
import Otp from "./Otp";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = () => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };


    const [modalNo, setModalNo] = useState(1);
    const [cred, setCred] = useState({ email: "", pass: "" });
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


    const handleClose = () => setOpen(false);

    const changeModalThree = () => setModalNo(3);
    const getCred = (email, pass) => setCred({ email, pass });

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUserInfo({
                    id: user.attributes.sub,
                    email: user.attributes.email,
                    firstName: user.attributes.given_name,
                    lastName: user.attributes.family_name
                });
            })
            .catch(() => {
                setUserInfo(null);
            });
    }, []);

    const bookingCheck = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            navigate("/booking");
        } catch {
            handleClickOpen();
        }
    };

    const changeModal = (m) => {
        setModalNo(m === 1 ? 2 : 1);
    };

    const logout = async () => {
        try {
            await Auth.signOut();
            setUserInfo(null);
            navigate("/");
            window.location.reload();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    };

    return (
        <div className="flex flex-col px-20 pb-10 mt-6 w-full bg-white border border-black border-solid max-md:px-5 max-md:max-w-full">
            <div className="shrink-0 h-px border border-solid bg-neutral-200 border-neutral-200 w-full" />
            <div className="flex justify-between items-center mt-9 w-full max-md:flex-col">
                <div className="flex flex-col self-start max-md:w-full">
                    <button className="text-2l leading-9 text-black" onClick={() => navigate("/")}>
                        www.theshepherdfarm.com.my
                    </button>
                    <div className="flex gap-2 mt-4">
                        <a href="https://www.facebook.com/theshepherdfarmsarikei/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon
                                fontSize="large"
                                className="shrink-0 w-10 aspect-square"
                            />
                        </a>
                        <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                            <YouTubeIcon
                                fontSize="large"
                                className="shrink-0 w-10 aspect-square"
                            />
                        </a>
                        <a href="https://www.instagram.com/theshepherdfarmsarikei/" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon
                                    fontSize="large"
                                    className="shrink-0 w-10 aspect-square"
                                />
                            </a>
                        <a href="../../admin/pages/dashboard" target="_blank" rel="noopener noreferrer">
                            <AdminPanelSettingsIcon
                                fontSize="large"
                                className="shrink-0 w-10 aspect-square"
                            />
                        </a>
                    </div>
                </div>
                <div className="hidden md:flex md:flex-grow md:justify-end md:items-center">
                    <div className="flex flex-col items-end w-[33%]">
                        <Typography variant="h6" className="text-black">Home</Typography>
                        <button
                        className="hidden md:flex md:flex-grow md:justify-end md:items-center"
                        onClick={() => bookingCheck()}
                    >
                        Book Now
                    </button>
                    </div>
                    <div className="flex flex-col items-end w-[33%]">
                        <Typography variant="h6" className="text-black">Map</Typography>
                        <button className="text-left mt-2" onClick={() => navigate("/location")}>Location</button>
                        <button className="text-left mt-2" onClick={() => navigate("/transportation")}>Transportation</button>
                    </div>
                    <div className="flex flex-col items-end w-[33%]">
                        <Typography variant="h6" className="text-black">Events</Typography>
                        <button className="text-left mt-2" onClick={handleDialogOpen}>Terms & Conditions</button>
                    </div>
                </div>
                <div className="flex md:hidden mt-4">
                    <IconButton onClick={toggleExpand}>
                        <ExpandMoreIcon />
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded} className="md:hidden">
                <div className="flex flex-col mt-4">
                    <Divider />
                    <div className="flex flex-col mt-2">
                        <Typography variant="h6" className="text-black">Home</Typography>
                        <button
                        className="hidden md:flex md:flex-grow md:justify-end md:items-center"
                        onClick={() => bookingCheck()}
                    >
                        Book Now
                    </button>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex flex-col mt-2">
                        <Typography variant="h6" className="text-black">Map</Typography>
                        <button className="text-left mt-2" onClick={() => navigate("/location")}>Location</button>
                        <button className="text-left mt-2" onClick={() => navigate("/transportation")}>Transportation</button>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex flex-col mt-2">
                        <Typography variant="h6" className="text-black">Events</Typography>
                        <button className="text-left mt-2" onClick={handleDialogOpen}>Terms & Conditions</button>
                    </div>
                </div>
            </Collapse>
            <Dialog open={openDialog} onClose={handleDialogClose}>
          {open}
                <DialogTitle>Terms & Conditions</DialogTitle>
                <DialogContent>
                    <TermsAndConditionsContent /> {/* 使用条款和条件组件 */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} sx={{
                        bgcolor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        '&:hover': {
                            bgcolor: 'black',
                            color: 'white',
                        }
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {modalNo === 1 ? (
                    <SignIn handleClose={handleClose} />
                ) : modalNo === 2 ? (
                    <SignUp changeModalThree={changeModalThree} getCred={getCred} />
                ) : (
                    <Otp email={cred.email} pass={cred.pass} handleClose={handleClose} />
                )}
                <DialogActions>
                    <Button onClick={() => changeModal(modalNo)}>Sign up!</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Footer;